import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import { CardActionArea } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const BlogCard = (props) => {

  const category = props.category
  const id = props.id
  const title = props.title
  const image = props.image
  const date = new Date(props.date).toDateString()//format at Month Day, Year
  const author = props.author
  const summary = props.summary
  const bucketKey = props.bucketKey
  const avatar = props.avatar
  const link = props.link


  const navigate = useNavigate();

  const toBlogDetails = () => {
    if(!link){

      navigate("/blog/".concat(title), {
        state: {
          id: id,
          title: title,
          bucketKey: bucketKey
        }
      });
    }else{
      //different link
      window.open(link);
    }
  }
  
  
  let avatarImageUrl = "/static/images/logo2.png"
  if (author == "John") {
    avatarImageUrl = "/static/images/greninja-incineroar/incineroar.jpg"
  } else if (author == "Robert"){
    avatarImageUrl = "/static/images/greninja-incineroar/greninja.jpg"
  }else if (author == "The Doppelgamers"){
      avatarImageUrl = "/static/images/logo2.png"
  }else{
    avatarImageUrl = avatar

  }

  var cardFont = 'large'

  if(summary.length < 100){
    cardFont = 'large'
  }else if(summary.length > 100 && summary.length < 180){
    cardFont = 'medium'
  }else if(summary.length > 180){
    cardFont = 'small'
  }

  //margin alone
  //styling
  const rightAlignItem = {
    marginLeft: "auto"
  }
  //flex + margin
  //styling
  const leftAlignItem = {
    marginRight: "auto"
  }

  const parentFlexRight = {
    display: "flex",
    justifyContent: "flex-end"
  }

  return (
    <Card elevation={1} sx={{ display: 'flex', flexDirection: { xs: "row", md: "column" } }}>
      <Grid container spacing={1} sx={{ flexDirection: { xs: "row", md: "column" } }}
        justifyContent="center"
        alignItems="left">
        <Grid item xs={12} sm={12} md={12} >
        <CardHeader sx={{padding:"8px"}}
        avatar={
          <Avatar
          src={avatarImageUrl}
          align="left"
          style={{ marginRight: "10px"}}>
          {author}

        </Avatar>
        }
      
        title={author}
        subheader={date}
      />

          <CardActionArea onClick={() => toBlogDetails()}>
            <Box
              sx={{
                maxHeight: 200,
                height: 200,
                bgcolor: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                  opacity: [0.9, 0.8, 0.7],
                },
              }}>
              <CardMedia elevation={0}
                component="img"
                sx={{maxHeight: 200, height: 200 }}
                image={image}
                alt=""
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  bgcolor: 'rgba(0, 0, 0, 0.54)',
                  color: 'white',
                  padding: '10px',
                  minHeight: '100px',
                  height: '100px'
                }}
              >
                <Typography variant="h6" component="div" align="left">
                  {title}
                </Typography>
                </Box>
                
          

            </Box>
          </CardActionArea>

        </Grid>
        <Grid item xs={12} sm={12} md={12} >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}  >
            <CardContent sx={{ height: '100px', padding:'8px' }} >
            <Typography gutterBottom variant="body2" align='left' >
            {summary}
          </Typography>
             
            </CardContent>
            {/*RIGHT*/}
            <CardActions disableSpacing
              style={{ display: "flex", justifyContent: "flex-end" }}>
             
              <Button
                size="small"
                onClick={() => toBlogDetails()}>
                Read more
              </Button>
             
              <IconButton style={{ display: "flex", justifyContent: "flex-end" }} aria-label="share">
                <ShareIcon />
              </IconButton>
            </CardActions>
           
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}



class BlogMediaCard extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return <div><BlogCard
      id={this.props.id}
      title={this.props.title}
      image={this.props.image}
      date={this.props.date}
      author={this.props.author}
      summary={this.props.summary}
      bucketKey={this.props.bucketKey} 
      avatar={this.props.avatar}
      link={this.props.link}/>
    </div>;
  }
}

export default BlogMediaCard