import React, { useEffect, useState } from "react";
import '.././App.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from "axios";
import {useLocation, useParams} from 'react-router-dom';
import BlogArticleTemplate from "../components/BlogArticleTemplate";
import { API } from "../api"
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';

const itemData = [
  {
    img: '/static/images/games/ghost-of-tsushima-1.jpg',
    title: 'Bed',
  },
  {
    img: '/static/images/games/god-of-war-1.jpg',
    title: 'Books',
  },
  {
    img: '/static/images/games/greninja-and-incineroar.jpg',
    title: 'Sink',
  },
  {
    img: '/static/images/games/spiderman-1.jpg',
    title: 'Kitchen',
  },
  {
    img: '/static/images/games/ratchet-and-clank-1.jpeg',
    title: 'Blinds',
  },
  {
    img: '/static/images/games/smash-bros-ultimate-1.jpg',
    title: 'Chairs',
  },
  {
    img: '/static/images/games/the-last-of-us-part2-1.jpg',
    title: 'Laptop',
  },
  {
    img: '/static/images/games/uncharted-1.jpg',
    title: 'Doors',
  },
  {
    img: '/static/images/games/uncharted-1.jpg',
    title: 'Doors',
  },
  {
    img: '/static/images/games/smash-bros-ultimate-1.jpg',
    title: 'Chairs',
  },
  {
    img: '/static/images/games/the-last-of-us-part2-1.jpg',
    title: 'Laptop',
  },
  {
    img: '/static/images/games/uncharted-1.jpg',
    title: 'Doors',
  },
  {
    img: '/static/images/games/uncharted-1.jpg',
    title: 'Doors',
  },
  {
    img: '/static/images/games/smash-bros-ultimate-1.jpg',
    title: 'Chairs',
  },
  {
    img: '/static/images/games/the-last-of-us-part2-1.jpg',
    title: 'Laptop',
  },
  {
    img: '/static/images/games/uncharted-1.jpg',
    title: 'Doors',
  }
];


function VPGallery() {

    const location = useLocation()

    const [posts, setPosts] = useState('');
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
  
   
    useEffect(() => {
      handlePosts(location);
    }, []);
  
    const handlePosts = async (location) => {
      setLoading(true);
  
      let blogs = API + "/vp?prefix="+ location.state.prefix //this will call aws endpoint
      console.log(blogs)
      await axios.get(blogs)
        .then((response) => {
          console.log(response)
          setPosts(response.data.result)
  
        }).catch(error => {
          console.error(`Error: ${error}`);
        }).finally((x) => {
          setLoading(false);
  
        });
  
    };


  
    function displayPhotoGallery() {
        if (!loading && posts) {
          console.log(posts)
          return (
            <div>
              <Grid container direction="row" spacing={2} justifyContent="center">
              {posts.map((item) => (
              <Grid item xs={12} sm={6} md={4} lg={3} alignContent="center">
                <Card>
                  <CardMedia
                    component="img"
                    width="480"
                    image={item}
                  />
                </Card>
              </Grid>
              ))}
            </Grid>
            </div>
    
          );
        }
      }

    return  (
    <Box>
      <div>
      {displayPhotoGallery()}
        {/*}
      <Grid container direction="row" spacing={2} justifyContent="center">
        {itemData.map((item) => (
        <Grid item xs={12} sm={6} md={4} lg={3} alignContent="center">
            <Card>
              <CardMedia
                component="img"
                width="480"
                image={item.img}
              />
          </Card>
         </Grid>
         ))}
     </Grid>
        */}
      </div>
  </Box>)
  
  }
  
  export default VPGallery;