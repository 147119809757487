import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import { CardActionArea } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BlogMediaCard from './BlogMediaCard';
import NewsMediaCard from './NewsMediaCard';
const GenericCard = (props) => {

  let category = ""
  const data = props.data

  //if there is a bucketKey then this should show in blog article
  if(data.BucketKey != null){
    category = "blog"

  }else{
    //just redirect to external link
    category = "discord"
  }

  if (category == "blog") {

    return (
      <BlogMediaCard
        id={data.ArticleID}
        title={data.Title}
        image={data.Image}
        date={data.DatePosted}
        author={data.Author}
        summary={data.Summary}
        bucketKey={data.BucketKey}
        />)
  } 
  
  else if (category == "discord") {

    //remove the (@name)
    data.Author = data.Author.split('(')[0].trim()

    return (
      <NewsMediaCard
      id={data.ArticleID}
      image={data.Image}
      date={data.DatePosted}
      author={data.Author}
      summary={data.Summary}
      avatar={data.AvatarImage}
      link={data.Link}
        />)
  }
  else{
    return (<div></div>)
  }

}



class GenericMediaCard extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {

    return <div><GenericCard
    data={this.props.data}
   />
  </div>;
   
  }
}

export default GenericMediaCard

