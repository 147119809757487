import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { CardActionArea } from '@mui/material';
import EventSharpIcon from '@mui/icons-material/EventSharp';
import Button from '@mui/material/Button';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';

const EventCard = (props) => {

  const id = props.id
  const title = props.title
  const image = props.image
  const date = new Date(props.date).toDateString()//format at Month Day, Year
  const description = props.description
  const developer = props.description.split("\n")[0]
  const platforms = props.description.split("\n")[1]



  return (
    <Card elevation={1} sx={{ display: 'flex', flexDirection: { xs: "row", md: "column" } }}>
      <Grid container spacing={1} sx={{ flexDirection: { xs: "row", md: "column" } }}
        justifyContent="center"
        alignItems="left">
        <Grid item xs={12} sm={12} md={12} >
          <CardHeader sx={{ padding: "8px" }}
            avatar={
              <Avatar sx={{ bgcolor: "white" }}>
                <EventSharpIcon></EventSharpIcon>
              </Avatar>
            }

            title={date}
            subheader="Release Date"
          />


          <CardMedia elevation={0}
            component="img"
            sx={{ maxHeight: 250, height: 250 }}
            image={image}
            alt=""
          />


        </Grid>
        <Grid item xs={12} sm={12} md={12} >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}  >
            <CardContent sx={{ height: '100px', padding: '8px' }} >
              <Typography gutterBottom variant="h6" component="div">
                {title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {developer}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {platforms}
              </Typography>
            </CardContent>

            {/*RIGHT*/}
            <CardActions
              style={{ display: "flex", justifyContent: "flex-end" }}>


<Button
                size="small"
                >
              </Button>
             
              <IconButton style={{ display: "flex", justifyContent: "flex-end" }} aria-label="share">
              <EventSharpIcon ></EventSharpIcon>
              </IconButton>

            </CardActions>

          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}


class EventMediaCard extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return <div><EventCard
      id={this.props.id}
      image={this.props.image}
      title={this.props.title}
      description={this.props.description}
      date={this.props.date}
      platforms={this.props.platforms} />
    </div>;
  }
}

export default EventMediaCard