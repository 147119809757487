import React, { useEffect, useState } from "react";
import '.././App.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import BlogMediaCard from '../components/BlogMediaCard'
import axios from "axios";
import { API } from "../api"
import Container from '@mui/material/Container';
import Divider, { dividerClasses } from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import BlogArticleForm from "../components/BlogArticleForm";
import Button from '@mui/material/Button';
import CreateIcon from '@mui/icons-material/Create';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import GenericMediaCard from "../components/GenericMediaCard";
import EventMediaCard from "../components/EventMediaCard";
import NewsMediaCard from "../components/NewsMediaCard";
//import Swipers
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

import "../components/SwiperStyles.css";
import { Autoplay, Pagination, Navigation, FreeMode, Thumbs } from "swiper";
//import BasicTabs from "../components/BasicTabs";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Skeleton from '@mui/material/Skeleton';
import Avatar from '@mui/material/Avatar';
import NewsMediaCardSkeleton from "../components/NewsMediaCardSkeleton";
import IconButton from '@mui/material/IconButton';
import PostAddIcon from '@mui/icons-material/PostAdd';
import Tooltip from '@mui/material/Tooltip';
import BlogFormDialog from "../components/BlogFormDialog";

const dummyCards = ['1', '2', '3', '4', '5', '6']


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function () {

  const [posts, setPosts] = useState('');
  const [events, setEvents] = useState('');
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [isSignedIn, setIsSignedIn] = React.useState(false);
  const [isDiscordStatus, setDiscordStatus] = useState('');
  const [discordMessages, setDiscordMessages] = useState('');
  const [isRobertOnline, setRobertOnline] = useState('');
  const [isJohnOnline, setJohnOnline] = useState('');
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);
  const [thumbsSwiperEvents, setThumbsSwiperEvents] = React.useState(null);
  const [isBlogFormDialogOpen, setIsBlogFormDialogOpen] = useState(false);



  const [profile, setProfile] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("profile");
    const initialValue = JSON.parse(saved);

    if (initialValue != null) {
      //console.log("username found")
      return initialValue["username"]
    } else {
      //console.log("blog not signed in")
      return null
    }

  });

  const [showWriteBlogPostFields, setShowWriteBlogPostFields] = React.useState(false);


  const toggleEditMode = () => {
    //console.log("Toggling Edit Mode")
    setShowWriteBlogPostFields(!showWriteBlogPostFields)
  }

  const checkIsSignedIn = () => {
    /*//debugging
    setIsSignedIn(true)
    return
    //end debugging
    */
    if (profile == null) {
      setIsSignedIn(false)

    } else {
      console.log("profile not null")
      console.log(profile)
      if (profile == "rmfisher" || profile == "jkfisher" || profile == "thedoppelgamers") {
        setIsSignedIn(true)
      }
    }
   
  }


  useEffect(() => {
    handlePosts();
    checkIsSignedIn();
    handleDiscordEvents()
    handleDiscordMessages()

  }, []);




  const handlePosts = async () => {
    setLoading(true);

    let blogs = API + "/blogs" // this will call aws endpoint

    await axios.get(blogs)
      .then((response) => {
        setPosts(response.data.result)
      }).catch(error => {
        console.error(`Error: ${error}`);
      }).finally((x) => {
        setLoading(false);

      });

  };

  const handleDiscordEvents = async () => {
    setLoading(true);

    let blogs = API + "/discord/events/python" // this will call aws endpoint

    await axios.get(blogs)
      .then((response) => {
        setEvents(response.data)
        //console.log(response.data)
      }).catch(error => {
        console.error(`Error: ${error}`);

      }).finally((x) => {
        setLoading(false);

      });

  };

  const handleDiscordMessages = async () => {
    setLoading(true);

    let blogs = API + "/discord/messages" // this will call aws endpoint

    await axios.get(blogs)
      .then((response) => {
        //console.log(response)
        setDiscordMessages(response.data.result)
      }).catch(error => {
        console.error(`Error: ${error}`);
      }).finally((x) => {
        setLoading(false);
      });

  };


  const handleDiscordStatus = async () => {
    setLoading(true);

    let blogs = API + "/discord" // this will call aws endpoint

    await axios.get(blogs)
      .then((response) => {
        //console.log(response)
        setDiscordStatus(response.data.result)

        console.log(isDiscordStatus)
        //check if online
        response.data.map(member => {
          if (member.displayName == "rmfisher") {
            console.log(member.displayName + " is online")
            setRobertOnline(member);
            console.log(isRobertOnline)
          }
          if (member.displayName == "jkfiher97") {
            console.log(member.displayName + " is online")
            setJohnOnline(member);
            console.log(isJohnOnline)

          }
        });

      }).catch(error => {
        console.error(`Error: ${error}`);
      }).finally((x) => {
        setLoading(false);

      });

  };

  const handleWriteNewBlogPost = (event) => {
    console.log("Making new page...")
};



  function displayBlogForm() {
    if (isSignedIn) {
      return (

        <div><BlogFormDialog/>

          {showWriteBlogPostFields ? <BlogFormDialog /> : null}
        </div>
      )
    }
  }

  function displayCarousel() {
    if (!loading && discordMessages && events) {

      return (
        <div>
          {/*START DOUBLE CAROUSEL*/}
          <Grid container direction="row" spacing={4} >

            <Grid item xs={12} sm={12} md={9} lg={9} alignContent="center">
              <Typography variant="h4" component="div" align="center">
                Top Stories
              </Typography>
              <Swiper
                slidesPerView={1}
                spaceBetween={20}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  700: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                }}

                autoplay={{
                  delay: 8000,
                  disableOnInteraction: false,
                }}
                scrollbar={{
                  hide: false,
                }}
                modules={[Autoplay, Scrollbar]}
                className="mySwiper"
              >

                {discordMessages.slice(0, 4).map((m) => (
                  <SwiperSlide>
                    <Grid container direction="row" spacing={4} >
                      <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">
                        {/*<GenericMediaCard
                          data={m}>
                </GenericMediaCard>*/}
                        <NewsMediaCard
                          id={m['ArticleID']}
                          image={m['Image']}
                          date={m['DatePosted']}
                          author={m['Author']}
                          summary={m['Summary']}
                          avatar={m['AvatarImage']}
                          link={m['Link']}
                          featured>
                        </NewsMediaCard>
                      </Grid>
                    </Grid>
                  </SwiperSlide>
                ))}


              </Swiper>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3} alignContent="center">
              <Typography variant="h4" component="div" align="center">
                Events
              </Typography>

              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                loop={true}

                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                scrollbar={{
                  hide: false,
                }}
                modules={[Autoplay, Scrollbar]}
                className="mySwiper"
              >
                {events.map((m) => (
                  <SwiperSlide>
                    <Grid container direction="row" spacing={4} >
                      <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">
                        <EventMediaCard
                          id={m['id']}
                          image={"https://cdn.discordapp.com/guild-events/" + m['id'] + "/" + m['image'] + "?size=1024"}
                          title={m['name']}
                          description={m['description']}
                          date={new Date(m['scheduled_start_time']).toDateString()}
                          platforms={m['platforms']}>
                        </EventMediaCard>

                      </Grid>
                    </Grid>
                  </SwiperSlide>
                ))}
              </Swiper>

            </Grid>
          </Grid>
          {/* END DOUBLE CAROUSEL*/}
        </div>
      );
    }
  }

  function displayCarouselWithThumbnail() {



    if (!loading && discordMessages && events) {
      return (
        <div>
          {/*START DOUBLE CAROUSEL*/}
          <Grid container direction="row" spacing={4} >
            {/*NEWS*/}
            <Grid item xs={12} sm={12} md={9} lg={9} alignContent="center">
              <Typography variant="h4" component="div" align="center">
                Top Stories
              </Typography>
              <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">

                <Swiper

                  slidesPerView={1}
                  thumbs={{ swiper: thumbsSwiper }}
                  navigation={true}
                  spaceBetween={10}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    700: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                  }}

                  autoplay={{
                    delay: 8000,
                    disableOnInteraction: false,
                  }}
                  scrollbar={{
                    hide: false,
                  }}
                  modules={[Autoplay, FreeMode, Navigation, Thumbs]}
                  className="topSwiper"
                >

                  {discordMessages.slice(0, 6).map((m) => (
                    <SwiperSlide>
                      <Grid container direction="row" spacing={4} >
                        <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">
                          <NewsMediaCard
                            id={m['ArticleID']}
                            image={m['Image']}
                            date={m['DatePosted']}
                            author={m['Author']}
                            summary={m['Summary']}
                            avatar={m['AvatarImage']}
                            link={m['Link']}
                            featured>
                          </NewsMediaCard>
                        </Grid>
                      </Grid>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Grid>
              <Grid container direction="row" spacing={4} >
                <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">

                  <Swiper
                    onSwiper={setThumbsSwiper}
                    slidesPerView={3}
                    spaceBetween={10}
                    breakpoints={{
                      640: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                      },
                      700: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                      },
                      1024: {
                        slidesPerView: 6,
                        spaceBetween: 10,
                      },
                    }}

                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="thumbSwiper"
                  >
                    {discordMessages.slice(0, 6).map((m) => (
                      <SwiperSlide>
                        <img src={m['Image']}  ></img>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Grid>
              </Grid>
            </Grid>
            {/*EVENTS*/}
            <Grid item xs={12} sm={12} md={3} lg={3} alignContent="center">

              <Typography variant="h4" component="div" align="center">
                Events
              </Typography>
              <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">

                <Swiper
                  slidesPerView={1}
                  thumbs={{ swiper: thumbsSwiperEvents }}
                  navigation={true}
                  spaceBetween={10}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    700: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                  }}

                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  scrollbar={{
                    hide: false,
                  }}
                  modules={[Autoplay, FreeMode, Navigation, Thumbs]}
                  className="eventSwiperTop"
                >

                  {events.map((m) => (
                    <SwiperSlide>
                      <Grid container direction="row" spacing={4} >
                        <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">
                          {<EventMediaCard
                            id={m['id']}
                            image={"https://cdn.discordapp.com/guild-events/" + m['id'] + "/" + m['image'] + "?size=1024"}
                            title={m['name']}
                            description={m['description']}
                            date={new Date(m['scheduled_start_time']).toDateString()}
                            platforms={m['platforms']}>
                          </EventMediaCard>}


                        </Grid>
                      </Grid>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Grid>
              <Grid container direction="row" spacing={4} >
                <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">

                  <Swiper
                    onSwiper={setThumbsSwiperEvents}
                    slidesPerView={3}
                    spaceBetween={10}

                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="thumbSwiper"
                  >
                    {events.slice(0, 6).map((m) => (
                      <SwiperSlide>
                        <img src={"https://cdn.discordapp.com/guild-events/" + m['id'] + "/" + m['image'] + "?size=1024"}  ></img>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* END DOUBLE CAROUSEL*/}
        </div>
      );
    }
  }


  function displayCarouselWithThumbnailSkeletons() {



    return (
      <div>
        {!loading && discordMessages && events ?
          <Grid container direction="row" spacing={4} >
            {/*NEWS*/}
            <Grid item xs={12} sm={12} md={9} lg={9} alignContent="center">
              <Typography variant="h4" component="div" align="center">
                Top Stories
              </Typography>
              <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">

                <Swiper
                  slidesPerView={1}
                  thumbs={{ swiper: thumbsSwiper }}
                  navigation={true}
                  spaceBetween={10}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    700: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                  }}

                  autoplay={{
                    delay: 8000,
                    disableOnInteraction: false,
                  }}
                  scrollbar={{
                    hide: false,
                  }}
                  modules={[Autoplay, FreeMode, Navigation, Thumbs]}
                  className="topSwiper"
                >

                  {discordMessages.slice(0, 6).map((m) => (
                    <SwiperSlide>
                      <Grid container direction="row" spacing={4} >
                        <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">
                          <NewsMediaCard
                            id={m['ArticleID']}
                            image={m['Image']}
                            date={m['DatePosted']}
                            author={m['Author']}
                            summary={m['Summary']}
                            avatar={m['AvatarImage']}
                            link={m['Link']}
                            featured>
                          </NewsMediaCard>
                        </Grid>
                      </Grid>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Grid>
              <Grid container direction="row" spacing={4} >
                <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">

                  <Swiper
                    onSwiper={setThumbsSwiper}
                    slidesPerView={3}
                    spaceBetween={10}
                    breakpoints={{
                      640: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                      },
                      700: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                      },
                      1024: {
                        slidesPerView: 6,
                        spaceBetween: 10,
                      },
                    }}

                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="thumbSwiper"
                  >
                    {discordMessages.slice(0, 6).map((m) => (
                      <SwiperSlide>
                        <img src={m['Image']}  ></img>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Grid>
              </Grid>
            </Grid>
            {/*EVENTS*/}
            <Grid item xs={12} sm={12} md={3} lg={3} alignContent="center">

              <Typography variant="h4" component="div" align="center">
                Events
              </Typography>
              <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">

                <Swiper
                  slidesPerView={1}
                  thumbs={{ swiper: thumbsSwiperEvents }}
                  navigation={true}
                  spaceBetween={10}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    700: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                  }}

                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  scrollbar={{
                    hide: false,
                  }}
                  modules={[Autoplay, FreeMode, Navigation, Thumbs]}
                  className="eventSwiperTop"
                >

                  {events.map((m) => (
                    <SwiperSlide>
                      <Grid container direction="row" spacing={4} >
                        <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">
                          {<EventMediaCard
                            id={m['id']}
                            image={"https://cdn.discordapp.com/guild-events/" + m['id'] + "/" + m['image'] + "?size=1024"}
                            title={m['name']}
                            description={m['description']}
                            date={new Date(m['scheduled_start_time']).toDateString()}
                            platforms={m['platforms']}>
                          </EventMediaCard>}


                        </Grid>
                      </Grid>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Grid>
              <Grid container direction="row" spacing={4} >
                <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">

                  <Swiper
                    onSwiper={setThumbsSwiperEvents}
                    slidesPerView={3}
                    spaceBetween={10}

                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="thumbSwiper"
                  >
                    {events.slice(0, 6).map((m) => (
                      <SwiperSlide>
                        <img src={"https://cdn.discordapp.com/guild-events/" + m['id'] + "/" + m['image'] + "?size=1024"}  ></img>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          : <Grid container direction="row" spacing={4} >
            {/*NEWS*/}
            <Grid item xs={12} sm={12} md={9} lg={9} alignContent="center">
              <Typography variant="h4" component="div" align="center">
                Top Stories
              </Typography>
              <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">
                <Swiper

                  slidesPerView={1}
                  thumbs={{ swiper: thumbsSwiper }}
                  navigation={true}
                  spaceBetween={10}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    700: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                  }}

                  autoplay={{
                    delay: 8000,
                    disableOnInteraction: false,
                  }}
                  scrollbar={{
                    hide: false,
                  }}
                  modules={[Autoplay, FreeMode, Navigation, Thumbs]}
                  className="topSwiper"
                >

                  {dummyCards.slice(0, 6).map((m) => (
                    <SwiperSlide>
                      <Grid container direction="row" spacing={4} >
                        <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">
                          <NewsMediaCard
                            isLoading
                            featured>
                          </NewsMediaCard>
                        </Grid>
                      </Grid>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Grid>
              <Grid container direction="row" spacing={4} >
                <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">

                  <Swiper
                    onSwiper={setThumbsSwiper}
                    slidesPerView={3}
                    spaceBetween={10}
                    breakpoints={{
                      640: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                      },
                      700: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                      },
                      1024: {
                        slidesPerView: 6,
                        spaceBetween: 10,
                      },
                    }}

                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="thumbSwiper"
                  >
                    {dummyCards.slice(0, 6).map((m) => (
                      <SwiperSlide>
                        <Skeleton variant="rectangular" width={200} height={100} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Grid>
              </Grid>
            </Grid>
            {/*EVENTS*/}
            <Grid item xs={12} sm={12} md={3} lg={3} alignContent="center">

              <Typography variant="h4" component="div" align="center">
                Events
              </Typography>
              <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">

                <Swiper
                  slidesPerView={1}
                  thumbs={{ swiper: thumbsSwiperEvents }}
                  navigation={true}
                  spaceBetween={10}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    700: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                  }}

                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  scrollbar={{
                    hide: false,
                  }}
                  modules={[Autoplay, FreeMode, Navigation, Thumbs]}
                  className="eventSwiperTop"
                >

                  {dummyCards.map((m) => (
                    <SwiperSlide>
                      <Grid container direction="row" spacing={4} >
                        <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">
                          <NewsMediaCard isLoading></NewsMediaCard>
                        </Grid>
                      </Grid>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Grid>
              <Grid container direction="row" spacing={4} >
                <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">

                  <Swiper
                    onSwiper={setThumbsSwiperEvents}
                    slidesPerView={3}
                    spaceBetween={10}

                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="thumbSwiper"
                  >
                    {dummyCards.slice(0, 6).map((m) => (
                      <SwiperSlide>
                        <Skeleton variant="rectangular" width={200} height={100} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>}
      </div>
    );
  }


  //shows blog cards but includes different data passed in if different type (Blog, Discord/Twitter)
  function displayBlogArticles() {

    if ((!loading && posts)) {
      return (
        <div>
          <br />
          <Grid container direction="row" spacing={2} >
            {posts.slice(0, posts.length).map((m) => (
              <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">
                <GenericMediaCard
                  data={m}>
                </GenericMediaCard>

              </Grid>
            ))}
          </Grid>
        </div>
      );
    }
  }

  //shows blog cards but includes different data passed in if different type (Blog, Discord/Twitter)
  function displayDiscordMessages() {

    if ((!loading && discordMessages)) {
      return (
        <div>
          <br />
          <Grid container direction="row" spacing={2} >
            {discordMessages.slice(6, discordMessages.length).map((m) => (
              <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">
                <GenericMediaCard
                  data={m}>
                </GenericMediaCard>

              </Grid>
            ))}
          </Grid>
        </div>
      );
    }
  }


  //creates left-to-right slider of news and blogs
  function displaySliderDiscordNewsAndBlogs() {
    return (
      <Grid container direction="row" spacing={2} >
        <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">

          <NewsDiscordBlogTabs />
        </Grid>
      </Grid>

    );
  }

  function NewsDiscordBlogTabs() {
    const [tabValue, setTabValue] = React.useState(0);
    const [swiper, setSwiper] = React.useState(null);

    const handleSlideChange = (event, newValue) => {
      //console.log("handleSlideChange")
      setTabValue(swiper.activeIndex);

    };

    const initSwiper = (event, newValue) => {
      //console.log("init swiper")
      setSwiper(document.querySelector('.discordBlogSwiper').swiper)

    };

    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };

    const handleDiscordNewsButtonSwitch = () => {
      swiper.slideTo(0)

    }

    const handleBlogButtonSwitch = () => {
      swiper.slideTo(1)

    }


    return (
      <div>
        
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth" aria-label="basic tabs example">
              <Tab label="News" {...a11yProps(0)} onClick={() => {
                handleDiscordNewsButtonSwitch();
              }} />
              <Tab label="Blog" {...a11yProps(1)} onClick={() => {
                handleBlogButtonSwitch();
              }} /> 
             

             {displayBlogForm()}
                   
              
              {/*<Tab label="Gaming" {...a11yProps(2)} />*/}
            </Tabs>
          </Box>
        </Box>
        <Grid container direction="row" spacing={4} >
          <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">

            <Swiper
              onInit={initSwiper}
              onSlideChange={handleSlideChange}
              navigation={true}
              slidesPerView={1}
              spaceBetween={10}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                800: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
              }}
              scrollbar={{
                hide: true,
              }}
              modules={[Navigation]}
              className="discordBlogSwiper"
            >

              <SwiperSlide>
                {displayDiscordMessages()}
              </SwiperSlide>
              <SwiperSlide>
                {displayBlogArticles()}
              </SwiperSlide>


            </Swiper>
          </Grid>
        </Grid></div>
    );
  }

  return (
    <Box>

      
      {displayCarouselWithThumbnailSkeletons()}
      {displaySliderDiscordNewsAndBlogs()}

    </Box>)
}
