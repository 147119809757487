import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import ShareIcon from '@mui/icons-material/Share';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TwitterIcon from '@mui/icons-material/Twitter';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LoopIcon from '@mui/icons-material/Loop';
import { pink, grey } from '@mui/material/colors';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
//onst largeScreen = useMediaQuery(theme => theme.breakpoints.up('md'));
import moment from 'moment';
import LaunchIcon from '@mui/icons-material/Launch';
import Chip from '@mui/material/Chip';
import TagIcon from '@mui/icons-material/Tag';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Popover from '@mui/material/Popover';
import Link from '@mui/material/Link';
import Carousel from 'react-material-ui-carousel'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TwitterMediaCarousel from './TwitterMediaCarousel';
import DOMPurify from 'dompurify'
const TwitterCard = (props) => {

  const id = props.id
  const title = props.title
  const image = props.image
  const video = props.video

  const media_type = props.media_type
  const media = props.media
  const date = props.date
  const author = props.author
  const summary = props.summary
  const tweet_url = props.tweet_url
  const hashtags = props.hashtags
  const metrics = props.metrics
  const mentions = props.mentions
  const link = props.link
  const twitter_url = "https://twitter.com/"

  const [hashEl, setHashEl] = React.useState(null);
  const [mentionEl, setMentionEl] = React.useState(null);
  const purify = DOMPurify(window);
  //allow targets to stay here
  purify.setConfig({ ADD_ATTR: ['target'] });


  function openMentionPopover(event) {
    if (mentions && mentions.length > 0) {
      setMentionEl(event.currentTarget);
    }
  };

  function openHashPopover(event) {
    if (hashtags && hashtags.length > 0) {
      setHashEl(event.currentTarget);
    }
  };



  function handleClose() {
    setMentionEl(null);
    setHashEl(null);
  };

  const [open, setOpen] = React.useState(false);
  const handleCloseBackdrop = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };


  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('xl');


  const openPop = Boolean(mentionEl);
  const openHash = Boolean(hashEl);

  const popid = openPop ? 'simple-popover' : undefined;
  const hashId = openHash ? 'simple-popover' : undefined;

  function displayHashtags() {

    return (<Stack direction="row" spacing={0}>


      {hashtags.map((h) => (
        <Chip label={"#" + h} color="error" clickable />
      ))}
    </Stack>)

  }

  function displayMentions() {

    let url = "https://twitter.com/"

    return (<Stack direction="row" spacing={1}>
      {mentions.map((m) => (
        <Chip label={"@" + m} color="primary" component="a" href={url + m} target="_blank" clickable />
      ))}
    </Stack>)

  }

  function kFormatter(num) {
    return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
  }

  function relativeDate(date) {
    // this is mostly working. seems to be off by an hour
    return moment(date).fromNow();
  }

  const handleClick = (link) => {
    window.open(link);
  };

  const expand = (src) => {
    window.open(src, "_blank");
  };


  function Item(props) {
    return (
      <div style={{ display: 'inline-block', textAlign: "right" }}>
        <Box
          sx={{
            maxHeight: 350,
            height: 350,
            bgcolor: 'primary.main',
            '&:hover': {
              backgroundColor: 'primary.dark',
              opacity: [0.9, 0.8, 0.7],
            },
            cursor: 'pointer'
          }}>
          <CardMedia elevation={4}
            component={props.media_type == "video" ? "img" : "img"}
            image={props.preview}
            autoPlay={false}
            controls
            height="350px"
            sx={{ backgroundColor: 'black' }}
            alt="Show more"
            title={props.media_type == "video" ? "Play Video" : "View Image"}
            onClick={() => handleToggle()}
          />
          <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleToggle}
          >
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText>
              </DialogContentText>
              <Box
                noValidate
                component="form"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  m: 'auto',
                  width: '100%',
                  textAlign: 'center'
                }}
              >
                {props.media_type == "video" ?
                  //<video controls autoPlay>
                  //  <source src={props.src} type="video/mp4"></source>
                  //</video>: 
                  <CardMedia elevation={4}
                    component="video"
                    image={props.src}
                    height="700px"
                    controls
                    autoPlay={true}
                    sx={{ backgroundColor: 'white' }}
                  ></CardMedia>
                  :
                  props.isCarousel == true ?
                    <TwitterMediaCarousel
                      media={media}
                      media_type={media_type}
                      tweet_url={tweet_url}
                      fullWidth={fullWidth}
                    >

                    </TwitterMediaCarousel>
                    :
                    <CardMedia elevation={4}
                      component="img"
                      image={props.src}
                      height="700px"
                      sx={{ backgroundColor: 'black' }}
                    ></CardMedia>

                  //{/*<img src={props.src}></img>*/}
                }
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleToggle}>Close</Button>
            </DialogActions>
          </Dialog>

        </Box>

      </div>
    )
  }


  function formatTitle(text) {

    var s = text
    let words = s.split(" ")
    //s.split(" ").forEach(word => console.log(word)


    words.forEach(word => {

      let isHashTag = word.includes("#")
      let isMention = word.includes("@")
      if (isHashTag) {
        let clean_word = word.replace(",", "")
        clean_word = clean_word.replace("#", "")
        clean_word = clean_word.replace(".", "")
        clean_word = clean_word.replace(":", "")
        clean_word = clean_word.replace("!", "")

        text = text.replace(word, '<a href=\"' + twitter_url + "hashtag/" + clean_word + "\" target=\"_blank\">" + word + "</a>");

      } else if (isMention) {
        let clean_word = word.replace(",", "")
        clean_word = clean_word.replace("@", "")
        clean_word = clean_word.replace(".", "")
        clean_word = clean_word.replace(":", "")
        clean_word = clean_word.replace("!", "")
        text = text.replace(word, '<a href=\"' + twitter_url + clean_word + "\" target=\"_blank\">" + word + "</a>");
      }


    })

    let result = '<span>' + text + '</span>'

    console.log(result)
    return result

  }


  return (
    <Card elevation={0} sx={{ display: 'flex', flexDirection: { xs: "row", md: "column" } }}>
      <Grid container spacing={1} sx={{ flexDirection: { xs: "row", md: "column" } }}
        justifyContent="center"
        alignItems="left">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {media.length > 1 ?
            <Carousel
              height="350px"
              animation="fade"
              navButtonsAlwaysVisible="true"
              interval="5000"
              stopAutoPlayOnHover="true"
              indicators={false}
            >
              {media.map((m) => (
                <Item key={m['url']}
                  preview={m['url']}
                  src={m['url']}
                  tweet_url={tweet_url}
                  media_type={m['type']}
                  isCarousel={true}
                >
                </Item>
              ))}

            </Carousel>
            :
            <Item key={image}
              preview={image}
              src={media_type == "video" ? video : image}
              tweet_url={tweet_url}
              media_type={media_type}
              isCarousel={false}

            >
            </Item>

          }
        </Grid>
        <Grid item xs={12} sm={12} md={6} >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}  >
            <CardContent sx={{ width: '550px', height: '100%' }} >
              <Grid container spacing={2}>


                <Grid item xs={1}>
                  <a href={author['url']} title={"View @" + author['username']} target="_blank" >
                    <Avatar alt={author['name']} src={author['profile_image']} align="left" />

                  </a>
                </Grid>
                <Grid item xs={11}>

                  <Typography variant="h4" component="div" align="left">
                    {author['name']}

                  </Typography>

                  <Typography variant="subtitle1" component="div" align="left" color="text.secondary">
                    <Link href={author['url']} target="_blank" underline="hover">@{author['username']}</Link> - {relativeDate(date)}
                  </Typography>

                </Grid>


                <Grid item xs={12}>
                  <Typography component="div" variant="body1" align="left" minHeight={20} dangerouslySetInnerHTML={{ __html: (purify.sanitize(formatTitle(title))) }}>
                  </Typography>
                  <Typography component="div" variant="body1" align="left" minHeight={20}>
                    {link ? <span><Button variant="contained" size="small" onClick={() => handleClick(link)} endIcon={<LaunchIcon />}>
                      Read More
                    </Button>

                    </span> : ''}
                  </Typography>
                </Grid>


              </Grid>

            </CardContent>

            <CardActions disableSpacing>
              <CardActions>
                <div >
                  <a href={tweet_url} title="View Original" target="_blank" >
                    <TwitterIcon color="primary" sx={{ fontSize: "2.5rem" }} /></a>
                </div>
              </CardActions>
              <CardActions>

                <div title="Likes">
                  <Badge badgeContent={kFormatter(metrics.like_count)} color="primary" max={1000}>
                    <FavoriteIcon color="primary" sx={{ fontSize: "2.5rem", color: pink[500] }} />
                  </Badge>
                </div>
              </CardActions>
              <CardActions>
                <div title="Retweets">
                  <Badge badgeContent={kFormatter(metrics.retweet_count)} color="secondary" max={1000}>
                    <LoopIcon color="primary" sx={{ fontSize: "2.5rem" }} />
                  </Badge>
                </div>
              </CardActions>
              {/*}
              <CardActions>
                <div title="Hashtags">
                  <Badge badgeContent={hashtags.length} color="secondary" max={1000}>
                    <TagIcon aria-describedby={hashId} color="warning" sx={{ fontSize: "2.5rem" }} onClick={openHashPopover} />
                  </Badge>
                </div>

                <Popover
                  id={hashId}
                  open={openHash}
                  anchorEl={hashEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <CardContent sx={{ width: '300px', height: '100%' }} >
                    <Grid container rowSpacing={3} columnSpacing={3}>
                      <Grid item xs={12}>
                        {hashtags.map((h) => (
                          <Chip label={"#" + h} color="warning" clickable sx={{ margin: '2px' }} />
                        ))}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Popover>
              </CardActions>
              <CardActions>
                <div title="Mentions">
                  <Badge badgeContent={mentions.length} color="secondary" max={1000}>
                    <AlternateEmailIcon aria-describedby={popid} color="error" sx={{ fontSize: "2.5rem" }} onClick={openMentionPopover} />
                  </Badge>
                </div>

                <Popover
                  id={popid}
                  open={openPop}
                  anchorEl={mentionEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <CardContent sx={{ width: '300px', height: '100%' }} >
                    <Grid container rowSpacing={3} columnSpacing={3} >
                      <Grid item xs={12}>
                        {mentions.map((m) => (
                          <Chip label={"@" + m} color="error" component="a" href={twitter_url + m} target="_blank" clickable sx={{ margin: '2px' }} />
                        ))}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Popover>

              </CardActions>
                        */}
            </CardActions>

          </Box>

        </Grid>

      </Grid>

    </Card >

  );
}



class TwitterMediaCard extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return <div><TwitterCard
      id={this.props.id}
      title={this.props.title}
      image={this.props.image}
      video={this.props.video}
      media_type={this.props.media_type}
      media={this.props.media}
      date={this.props.date}
      author={this.props.author}
      summary={this.props.summary}
      tweet_url={this.props.tweet_url}
      hashtags={this.props.hashtags}
      mentions={this.props.mentions}
      metrics={this.props.metrics}
      link={this.props.link}
    />
    </div>;
  }
}

export default TwitterMediaCard