import * as React from 'react';
import { useEffect, useState } from "react";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { red, blue, green } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import axios from "axios";
import { API } from "../api"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function createData(GameID, Title, Platform, JohnHasPlayed, RobertHasPlayed) {
    return { GameID, Title, Platform, JohnHasPlayed, RobertHasPlayed };
}

const rows = [
    createData('1', 'The Lengend of Zelda: A Link to the Past', "Switch", true, false,),
    createData('2', 'The Lengend of Zelda: Twilight Princess', "Switch", true, true,),
    createData('3', 'The Lengend of Zelda: Skyward Sword', "Switch", true, true,),
    createData('4', 'The Lengend of Zelda: Breath of the Wild', "Switch", true, true,),
    createData('5', 'The Lengend of Zelda: Tears of the Kingdom', "Switch", true, true,),
];


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const GameTable = (props) => {

    //const gameRows = props.games
    const [gameRows, setGameRows] = useState(props.games);
    const [selectedGameID, setSelectedGameID] = useState('')
    const [loading, setLoading] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(props.isSignedIn);
    //const [isEditModeOn, setIsEditModeOn] = React.useState(props.isEditModeOn)
    const [showEditor, setShowEditor] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [platform, setPlatform] = React.useState('');
    const [hasJohnPlayed, setHasJohnPlayed] = React.useState(false);
    const [hasRobertPlayed, setHasRobertPlayed] = React.useState(false);

    const [selectedGame, setSelectedGame] = React.useState(null);

    //snackbar
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        snackMessage: '',
        severity: 'success'
    });
    const { vertical, horizontal, open, snackMessage } = state

    const handleDeleteClick = (event, GameID, SortLetter) => {
        //console.log(GameID)
        deleteGame(GameID, SortLetter)
    };

    const handleEditClick = (event, game) => {
        setSelectedGame(game)
        setTitle(game.Title)
        setPlatform(game.Platform)
        setSelectedGameID(game.GameID)
        setHasJohnPlayed(game.JohnHasPlayed)
        setHasRobertPlayed(game.RobertHasPlayed)
        setShowEditor(!showEditor)
    };

    const handleSaveClick = (event, game) => {
        //console.log("handleSaveClick")
        //save game with new data
        selectedGame.Title = title
        selectedGame.Platform = platform
        selectedGame.JohnHasPlayed = hasJohnPlayed
        selectedGame.RobertHasPlayed = hasRobertPlayed

        console.log(selectedGame)
        updateGame(selectedGame)
        //close the editor
        setShowEditor(!showEditor)
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);

    };

    const handlePlatformChange = (event) => {
        setPlatform(event.target.value);

    };

    const handleHasJohnPlayedChange = (event) => {
        //console.log("handleHasJohnPlayedChange")
        setHasJohnPlayed(event.target.value);

    };

    const handleHasRobertPlayedChange = (event) => {
        //console.log("handleHasRobertPlayedChange")
        setHasRobertPlayed(event.target.value);

    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const validateGameFields = () => {
        //console.log("validateGameFields...")
        if (selectedGame.Title && selectedGame.Platform) {

            return true
        }
        else {
            return false
        }
    };


    const updateGame = async () => {
        //console.log("updateGame...")
        if (!isSignedIn) {
            //console.log("Unable to add game; not signed in")
            setState({ open: true, snackMessage: 'Failed, Not Signed In', vertical: 'bottom', horizontal: 'center', severity: 'error' });
        } else if (!validateGameFields()) {
            setState({ open: true, snackMessage: 'Failed, Missing Required Information', vertical: 'bottom', horizontal: 'center', severity: 'error' });

        }

        else {
            //console.log("Submitting Game")
            setLoading(true);
            //convert to json
            const json =
            {
                "Action": "Update",
                "GameID": selectedGame.GameID,
                "Title": selectedGame.Title,
                "SortLetter": selectedGame.SortLetter,
                "Platform": selectedGame.Platform,
                "HasJohnPlayed": selectedGame.JohnHasPlayed,
                "HasRobertPlayed": selectedGame.RobertHasPlayed,
            }
            //add action
            //console.log(json)


            let gamesURL = API + "/games" // this will call aws endpoint
            const customConfig = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            //console.log(json)
            await axios.post(gamesURL, JSON.stringify(json), customConfig)
                .then((response) => {
                    //console.log(response)
                    //setPosts(response.data.result)

                }).catch(error => {
                    console.error(`Error: ${error}`);
                }).finally((x) => {
                    setLoading(false);
                    setState({ open: true, snackMessage: 'Updated Game', vertical: 'bottom', horizontal: 'center', severity: 'success' });
                });


        }
        //post to lambda function
    }

    const deleteGame = async (GameID, SortLetter) => {
        //console.log("Deleting Game")
        if (!isSignedIn) {
            //console.log("Unable to add game; not signed in")
            setState({ open: true, snackMessage: 'Failed, Not Signed In', vertical: 'bottom', horizontal: 'center', severity: 'error' });
        }

        else {

            setLoading(true);
            //convert to json
            const json =
            {
                "GameID": GameID,
                "SortLetter": SortLetter,
                "Action": "Delete"
            }

            //console.log(json)

            let gamesURL = API + "/games" // this will call aws endpoint
            const customConfig = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            await axios.post(gamesURL, JSON.stringify(json), customConfig)
                .then((response) => {
                    //console.log(response)
                    //setPosts(response.data.result)

                }).catch(error => {
                    console.error(`Error: ${error}`);
                }).finally((x) => {
                    setLoading(false);
                    setState({ open: true, snackMessage: 'Deleted Game', vertical: 'bottom', horizontal: 'center', severity: 'success' });

                });


        }

    }

    return (
        <div>      <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={5000} onClose={handleClose} key={vertical + horizontal}>
            <Alert onClose={handleClose} severity={state.severity} sx={{ width: '100%' }}>
                {snackMessage}
            </Alert>
        </Snackbar>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {isSignedIn ? <TableCell>ID</TableCell> : null}
                            <TableCell>Title</TableCell>
                            <TableCell>Platform</TableCell>
                            <TableCell align="center" colspan="2">Played</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {gameRows.map((row) => (

                            <TableRow
                                key={row.GameID}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >
                                {isSignedIn ?
                                    <TableCell >
                                        {showEditor && row.GameID == selectedGameID ?
                                            <IconButton>
                                                <SaveIcon onClick={(event) => handleSaveClick(event, row)} sx={{ color: green[300] }} />
                                            </IconButton> :
                                            <IconButton>
                                                <EditIcon onClick={(event) => handleEditClick(event, row)} />
                                            </IconButton>}
                                        <IconButton>
                                            <DeleteIcon onClick={(event) => handleDeleteClick(event, row.GameID, row.SortLetter)} sx={{ color: red[300] }} />
                                        </IconButton>
                                    </TableCell>
                                    : null}
                                <TableCell component="th" scope="row">
                                    {showEditor && row.GameID == selectedGameID ?
                                        <div><TextField
                                            required
                                            label="Title"
                                            defaultValue={row.Title}
                                            fullWidth
                                            onChange={handleTitleChange}

                                        />

                                        </div>
                                        : row.Title}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {showEditor && row.GameID == selectedGameID ?
                                        <div>
                                            <Select
                                                labelId="platform-select-label"
                                                id="platform-select"
                                                defaultValue={row.Platform}
                                                onChange={handlePlatformChange}
                                                fullWidth
                                            > <MenuItem value={"Game Boy"}>Game Boy</MenuItem>
                                                <MenuItem value={"Game Boy Advance"}>Game Boy Advance</MenuItem>
                                                <MenuItem value={"GameCube"}>GameCube</MenuItem>
                                                <MenuItem value={"DS"}>DS</MenuItem>
                                                <MenuItem value={"3DS"}>3DS</MenuItem>
                                                <MenuItem value={"Wii"}>Wii</MenuItem>
                                                <MenuItem value={"Switch"}>Switch</MenuItem>
                                                <MenuItem value={"PS1"}>PS1</MenuItem>
                                                <MenuItem value={"PS2"}>PS2</MenuItem>
                                                <MenuItem value={"PS3"}>PS3</MenuItem>
                                                <MenuItem value={"PS4"}>PS4</MenuItem>
                                                <MenuItem value={"PS5"}>PS5</MenuItem>
                                                <MenuItem value={"PSP"}>PSP</MenuItem>
                                                <MenuItem value={"PS Vita"}>PS Vita</MenuItem>
                                                <MenuItem value={"Xbox"}>Xbox</MenuItem>
                                                <MenuItem value={"Xbox 360"}>Xbox 360</MenuItem>
                                                <MenuItem value={"PC"}>PC</MenuItem>
                                            </Select>

                                        </div>
                                        : row.Platform}
                                </TableCell>


                                <TableCell align="center">
                                    {showEditor && row.GameID == selectedGameID ?
                                        <div><InputLabel id="john-played-select-label">John Played</InputLabel>

                                        <Select
                                            labelId="john-played-select-label"
                                            id="john-played-select"
                                            value={hasJohnPlayed}
                                            label="John Played"
                                            onChange={handleHasJohnPlayedChange}
                                            fullWidth
                                        >
                                            <MenuItem value={true}>True</MenuItem>
                                            <MenuItem value={false}>False</MenuItem>
                                        </Select></div>
                                        :
                                        <div>
                                            {row.JohnHasPlayed ? <div><CheckCircleIcon sx={{ color: red[600] }} /></div> : null}
                                        </div>
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {showEditor && row.GameID == selectedGameID ?
                                        <div><InputLabel id="robert-played-select-label">Robert Played</InputLabel>

                                        <Select
                                            labelId="robert-played-select-label"
                                            id="robert-played-select"
                                            value={hasRobertPlayed}
                                            label="Robert Played"
                                            onChange={handleHasRobertPlayedChange}
                                            fullWidth
                                        >
                                            <MenuItem value={true}>True</MenuItem>
                                            <MenuItem value={false}>False</MenuItem>
                                        </Select></div>
                                        :
                                        <div>
                                            {row.RobertHasPlayed ? <div><CheckCircleIcon sx={{ color: blue[600] }} /></div> : null}
                                        </div>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer></div>
    );
}


class GameLibraryTable extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {

        return <div><GameTable
            games={this.props.games}
            isSignedIn={this.props.isSignedIn}

        />
        </div>;

    }
}

export default GameLibraryTable