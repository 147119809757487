import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import Grid from '@mui/material/Grid';
import {
  Link,
} from 'react-router-dom';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

const pagesRouting = [
  /*{ 'name': 'News', 'to': '/', 'value': '/' },*/
  { 'name': 'Blog', 'to': '/blog', 'value': '/blog' },
  { 'name': 'Photography', 'to': '/vp', 'value': '/vp' },
  /*{ 'name': 'About', 'to': '/about', 'value': '/about' },*/
  /*{ 'name': 'Events', 'to': '/events', 'value': '/events' },
  { 'name': 'Recognitions', 'to': '/recognitions', 'value': '/recognitions' }*/

];
const settings = ['Profile', 'Logout'];

const Footer = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const twitter_url = "https://twitter.com/thedoppelgamers"
  const instagram_url = "https://www.instagram.com/thedoppelgamers/"
  const youtube_url = "https://www.youtube.com/@doppelgamers"



  return (
    <Box className='App-footer'>
      <Grid container spacing={1} sx={{ flexDirection: { xs: "row", md: "column" } }} justifyContent="center"
        alignContent="center"
        alignItems="center" >
        <Grid item xs={8}>
          <Grid container spacing={4} >

            {pagesRouting.map((page) => (
              <Grid item xs={3}>
                <MenuItem key={page['name']} to={page['to']}
                  value={page['value']}
                  component={Link}
                  handleChange={handleCloseNavMenu}
                >
                  <Typography textAlign="center">{page['name']}</Typography>
                </MenuItem>
              </Grid>





            ))}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={12} sx={{ flexDirection: { xs: "row", md: "row" } }} justifyContent="center"
            alignContent="center"
            alignItems="center" >
            <Grid item xs={4}>
              <div >
                <a href={twitter_url} title="Twitter" target="_blank" >
                  <TwitterIcon color="primary" sx={{ fontSize: "2.5rem" }} /></a>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div >
                <a href={instagram_url} title="Instagram" target="_blank" >
                  <InstagramIcon color="secondary" sx={{ fontSize: "2.5rem" }} /></a>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div >
                <a href={youtube_url} title="YouTube" target="_blank" >
                  <YouTubeIcon color="error" sx={{ fontSize: "2.5rem" }} /></a>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </Box>
  );
};
export default Footer;
