import React from "react";
import '.././App.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Gallery from '../components/Gallery'
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import PhotoActionAreaCard from "../components/ActionAreaCard";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const cards = [
  { 'id': 1, 'title': "Ghost of Tsushima", 'image': '/static/images/games/ghost-of-tsushima-1.jpg', 'summary':'Sucker Punch Productions, PS5', 'prefix':'ghost-of-tsushima/' },
  { 'id': 2, 'title': "God of War", 'image': '/static/images/games/god-of-war-1.jpg' , 'summary':'Santa Monica Studio, PS5', 'prefix':'god-of-war/'},
  { 'id': 3, 'title': "Greninja X Incineroar", 'image': '/static/images/games/greninja-and-incineroar.jpg', 'summary':'Nintendo, Nintendo Switch', 'prefix':'greninja-and-incineroar/'},
  { 'id': 4, 'title': "Marvel's Spider-Man", 'image': '/static/images/games/spiderman-1.jpg' , 'summary':'Insomniac Games, PS5', 'prefix':'spiderman/'},
  { 'id': 5, 'title': "Ratchet & Clank: Rift Apart", 'image': '/static/images/games/ratchet-and-clank-1.jpeg' , 'summary':'Insomniac Games, PS5', 'prefix':'ratchet-and-clank/'},
  { 'id': 6, 'title': "Super Smash Bros. Ultimate", 'image': '/static/images/games/smash-bros-ultimate-1.jpg' , 'summary':'Nintendo, Nintendo Switch', 'prefix':'super-smash-bros/'},
  { 'id': 7, 'title': "The Last of Us", 'image': '/static/images/games/the-last-of-us-part2-1.jpg' , 'summary':'Naughty Dog, PS5', 'prefix':'the-last-of-us/'},
  { 'id': 8, 'title': "Uncharted", 'image': '/static/images/games/uncharted-1.jpg' , 'summary':'Naughty Dog, PS5', 'prefix':'uncharted/'}]

  const itemData = [
    {
      img: '/static/images/games/ghost-of-tsushima-1.jpg',
      title: 'Bed',
    },
    {
      img: '/static/images/games/god-of-war-1.jpg',
      title: 'Books',
    },
    {
      img: '/static/images/games/greninja-and-incineroar.jpg',
      title: 'Sink',
    },
    {
      img: '/static/images/games/spiderman-1.jpg',
      title: 'Kitchen',
    },
    {
      img: '/static/images/games/ratchet-and-clank-1.jpeg',
      title: 'Blinds',
    },
    {
      img: '/static/images/games/smash-bros-ultimate-1.jpg',
      title: 'Chairs',
    },
    {
      img: '/static/images/games/the-last-of-us-part2-1.jpg',
      title: 'Laptop',
    },
    {
      img: '/static/images/games/uncharted-1.jpg',
      title: 'Doors',
    },
    {
      img: '/static/images/games/uncharted-1.jpg',
      title: 'Doors',
    },
    {
      img: '/static/images/games/smash-bros-ultimate-1.jpg',
      title: 'Chairs',
    },
    {
      img: '/static/images/games/the-last-of-us-part2-1.jpg',
      title: 'Laptop',
    },
    {
      img: '/static/images/games/uncharted-1.jpg',
      title: 'Doors',
    },
    {
      img: '/static/images/games/uncharted-1.jpg',
      title: 'Doors',
    },
    {
      img: '/static/images/games/smash-bros-ultimate-1.jpg',
      title: 'Chairs',
    },
    {
      img: '/static/images/games/the-last-of-us-part2-1.jpg',
      title: 'Laptop',
    },
    {
      img: '/static/images/games/uncharted-1.jpg',
      title: 'Doors',
    }
  ];

export default function () {
  return  (
  <Box>
    <div>
      <h1>Virtual Photography</h1>

      <Grid container direction="row" spacing={2} justifyContent="center">
        {cards.map((m) => (
        <Grid item xs={12} sm={6} md={4} lg={3} alignContent="center">
          <PhotoActionAreaCard 
          key={m['id']} 
          title={m['title']} 
          image={m['image']} 
          width={480} 
          summary={m['summary']}
          prefix={m['prefix']}
          />
        </Grid>
      ))}
     </Grid>

    

    </div>
</Box>)

}
