import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Carousel from 'react-material-ui-carousel'


const TwitterCarousel = (props) => {


  const id = props.id
  const title = props.title
  const image = props.image
  const video = props.video

  const media_type = props.media_type
  const media = props.media
  const date = props.date
  const author = props.author
  const summary = props.summary
  const tweet_url = props.tweet_url
  const hashtags = props.hashtags
  const metrics = props.metrics
  const mentions = props.mentions
  const link = props.link
  const twitter_url = "https://twitter.com/"


  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };


  function Item(props) {

    return (

      <div style={{ display: 'inline-block', textAlign: "center" }}>
        <CardMedia elevation={4}
          component={props['item'].media_type == "video" ? "img" : "img"}
          image={props['item']['url']}
          autoPlay={false}
          controls
          height="700px"
          sx={{ backgroundColor: 'black' }}
          alt="Show more"
          title={props['item'].media_type == "video" ? "Play Video" : "View Image"}
          onClick={() => handleToggle()}
        />

      </div>
    )
  }



  return (

    <Card elevation={0} sx={{ display: 'flex', flexDirection: { xs: "row", md: "column" } }}>

      <Carousel
        animation="fade"
        navButtonsAlwaysVisible="true"
        interval="5000"
        stopAutoPlayOnHover="true"
        indicators={true}
      >
        {
          media.map((item, i) => <Item key={i} item={item} />)
        }
      </Carousel>

    </Card>




  );
}




class TwitterMediaCarousel extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return <div><TwitterCarousel
      id={this.props.id}
      title={this.props.title}
      image={this.props.image}
      video={this.props.video}
      media_type={this.props.media_type}
      media={this.props.media}
      date={this.props.date}
      author={this.props.author}
      summary={this.props.summary}
      tweet_url={this.props.tweet_url}
      link={this.props.link}
    />
    </div>;
  }
}

export default TwitterMediaCarousel