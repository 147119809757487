import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {
  Link,
} from 'react-router-dom';


function LinkTab(props) {

  const label = props.label
  const value = props.value
  const to = props.href

  return (
    <Tab
      label={label}
      value={value}
      to={to}
      component={Link}
     
    />
  );
}

const TabsWrappedLabel = () => {
  const [value, setValue] = React.useState("/");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', padding: '2%' }}>

      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
      >
        
          <Tab label="News" to="/" value="/" component={Link}/>
          <Tab label="Blog" to="/blog" value="blog"  component={Link}/>
          <Tab label="About" to="/about" value="/about"  component={Link} />
          <Tab label="Virtual Photography" to="/vp" value="/vp"  component={Link} />
          <Tab label="Events" to="/events"  value="/events"   component={Link}/>
          <Tab label="Recognitions" to="/recognitions"  value="/recognitions"  component={Link} />
      </Tabs>

    </Box>
  );
}

class Tabbar extends React.Component {
  render() {
    return <div><TabsWrappedLabel /></div>;
  }
}

export default Tabbar
