import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import DOMPurify from 'dompurify'
import { API } from "../api"
import axios from "axios";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useEffect, useRef } from 'react';
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import VisibilityIcon from '@mui/icons-material/Visibility';
//STYLES
const fabStyleEditSave = {
  margin: 0,
  top: 'auto',
  right: '2%',
  top: '12%',
  left: 'auto',
  position: 'fixed',


}
const fabStylePreview = {
  margin: 0,
  top: 'auto',
  right: '2%',
  top: '22%',
  left: 'auto',
  position: 'fixed'

}
const fabStyleAddParagraph = {
  margin: 0,
  top: 'auto',
  right: '2%',
  top: '32%',
  left: 'auto',
  position: 'fixed',

}
const fabStyleDeleteParagraph = {
  margin: 0,
  top: 'auto',
  right: '2%',
  top: '42%',
  left: 'auto',
  position: 'fixed',

}

const fabStyleDeleteArticle = {
  margin: 0,
  top: 'auto',
  right: '2%',
  top: '52%',
  left: 'auto',
  position: 'fixed',

}
//END SYTLES

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

//HEADER
const Header = (props) => {
  const [text, setText] = React.useState(props.text);
  const [color, setColor] = React.useState(props.color);

  return (
    <Typography variant="h4" component="div" align="left" sx={{ color: color }}>
      <b>{text}</b>
    </Typography>
  )
}

//PARAGRAPH contains Headers
const Paragraph = (props) => {
  const index = props.paragraphIndex
  const text = props.text
  const image = props.image
  const purify = DOMPurify(window);
  const contents = props.contents
  //is the entire page running "Edit" Mode
  const [isEditModeOn, setIsEditModeOn] = React.useState(props.isEditModeOn)
  //transform text to add hyperlinks
  const [value, setValue] = React.useState(text);
  const [headerValue, setHeaderValue] = React.useState(props.contents.Header);
  const [headerColor, setHeaderColor] = React.useState(props.headerColor);
  //are paragraphs being edited
  const [showEditor, setShowEditor] = React.useState(false);

  useEffect(() => {

    //console.log("Paragraph useEffect: " + props.isEditModeOn)
    setIsEditModeOn(props.isEditModeOn)
  }, [props.isEditModeOn])

  const handleHeadersChange = (event) => {
    setHeaderValue(event.target.value)

  }

  const toggleShowEditor = () => {
    setShowEditor(!showEditor)
  }

  const saveEditorChanges = () => {
    contents.Header = headerValue
    contents.Body[index]["Paragraph"] = value
    toggleShowEditor()
  }

  //check if image exists
  if (image) {
    return (
      <Grid container spacing={1} sx={{ flexDirection: { xs: "column", md: "row", lg: "row" } }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card >
            <CardMedia
              component="img"
              image={image}
              sx={{ maxHeight: 400 }}
            />
          </Card>

        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>

          {showEditor ?
            <div>
              <CKEditor
                editor={ClassicEditor}
                data={value}

                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                  //console.log( 'Editor is ready to use!', editor );
                }}
                onChange={(event, editor) => {
                  let data = editor.getData();
                  //console.log({ event, editor, data });
                  //setNewData(editor.getData())
                  setValue(editor.getData())

                }}
                onBlur={(event, editor) => {
                  //console.log( 'Blur.', editor );
                }}
                onFocus={(event, editor) => {
                  //console.log( 'Focus.', editor );
                }}
              />

              <Button
                size="large"
                onClick={() => saveEditorChanges()}
                variant="contained"
                endIcon={<SaveIcon />}

              >
                Save Text
              </Button>
            </div>
            :
            <div>


              <Typography variant="body1" component="div" align="justify" dangerouslySetInnerHTML={{ __html: (purify.sanitize(value)) }}>
              </Typography>
              {isEditModeOn ?
                <Button
                  size="large"
                  onClick={() => toggleShowEditor()}
                  variant="contained"
                  endIcon={<EditIcon />}>
                  Edit Text
                </Button>
                : null}
            </div>
          }
          <br></br>
        </Grid>
      </Grid>
    )
  } else {
    //console.log("no image")
    //console.log("isEditModeOn: " + isEditModeOn)

    return (
      <Grid item xs={12} sm={12} md={12} lg={12}>

        <br></br>
        {showEditor ?
          <div>
            <div>
              <TextField
                required
                label="Header"
                defaultValue={headerValue}
                fullWidth
                onChange={handleHeadersChange}

              />
              <CKEditor
                editor={ClassicEditor}
                data={value}

                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                  //console.log( 'Editor is ready to use!', editor );
                }}
                onChange={(event, editor) => {
                  let data = editor.getData();
                  //console.log({ event, editor, data });
                  //setNewData(editor.getData())
                  setValue(editor.getData())

                }}
                onBlur={(event, editor) => {
                  //console.log( 'Blur.', editor );
                }}
                onFocus={(event, editor) => {
                  //console.log( 'Focus.', editor );
                }}
              />
              <Button
                size="large"
                onClick={() => saveEditorChanges()}
                variant="contained"
                endIcon={<SaveIcon />}

              >
                Save Text
              </Button>
            </div>
          </div>

          :
          <div>
            <Header text={headerValue} color={headerColor} />
            <Typography variant="body1" component="div" align="justify" dangerouslySetInnerHTML={{ __html: (purify.sanitize(value)) }}>
            </Typography>

            {isEditModeOn ?
              <Button
                size="large"
                onClick={() => toggleShowEditor()}
                variant="contained"
                endIcon={<EditIcon />}>
                Edit Text
              </Button> : null}

          </div>
        }

      </Grid>
    )
  }
}


//ARTICLE contains Paragraphs
const Article = (props) => {

  //snackbar
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    snackMessage: '',
    severity: 'success'
  });
  const { vertical, horizontal, open, snackMessage } = state
  //
  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const id = props.id
  const image = props.image
  const date = new Date(props.date).toDateString()//format at Month Day, Year
  const [isEditModeOn, setIsEditModeOn] = React.useState(false)
  const [author, setAuthor] = React.useState(props.author);
  const [title, setTitle] = React.useState(props.title);
  const [datePosted, setDatePosted] = React.useState(props.date);
  const [summary, setSummary] = React.useState(props.summary);
  const [coverImage, setCoverImage] = React.useState(props.image);
  const [showEditor, setShowEditor] = React.useState(false);
  const [isSignedIn, setIsSignedIn] = React.useState(false);
  const [contents, setContents] = React.useState(props.contents)
  const caption = props.caption
  const bucketKey = props.bucketKey
  let bucketValues = bucketKey.split("/")
  let prefix = bucketValues[0]
  let key = bucketValues[1]

  const [loading, setLoading] = React.useState(false);
  const [posts, setPosts] = React.useState('');
  const [error, setError] = React.useState('');

  let headerColor = "black"
  let avatarImageUrl = "/static/images/logo.png"
  if (author == "John") {
    headerColor = "#c80707" //red
    avatarImageUrl = "/static/images/greninja-incineroar/incineroar.jpg"
  } else {
    headerColor = "#004aad" //blue
    avatarImageUrl = "/static/images/greninja-incineroar/greninja.jpg"

  }

  const authors = [
    {
      value: 'The Doppelgamers'
    },
    {
      value: 'Robert'
    },
    {
      value: 'John'
    }
  ];



  const handleAuthorChange = (event) => {
    setAuthor(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDatePostedChange = (event) => {
    setDatePosted(event.target.value);

  };

  const handleSummaryChange = (event) => {
    setSummary(event.target.value);
  };

  const handleCoverImageChange = (event) => {
    setCoverImage(event.target.value);
  };

  const previewChanges = () => {
    setIsEditModeOn(false)
  }

  const addParagraph = () => {
    let newfield = { Header: '[Header Placeholder]', Body: [{ Paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.', Image: '' }] };
    setContents([...contents, newfield])
  }

  const removeParagraph = (index) => {
    console.log("removing paragraph: " + index)
    let data = [...contents];
    data.splice(index, 1)
    setContents(data)
  }

  const toggleEditEntireBlog = () => {
    setIsEditModeOn(true)
  }

  const saveAllBlogChanges = () => {
    //toggleEditEntireBlog()
    setShowEditor(false)
    setIsEditModeOn(false)
    submitBlog()
  }

  const checkIsSignedIn = () => {

    const saved = localStorage.getItem("profile");
    const profileData = JSON.parse(saved);

    if (profileData != null) {
      if (profileData["username"] == "rmfisher"
        || profileData["username"] == "jkfisher"
        || profileData["username"] == "thedoppelgamers") {
        return true
      }
    } else {
      //console.log("blog not signed in")
      return false
    }
  }

  const validateBlogsFields = () => {
    if (title && author && summary && datePosted && coverImage) {
      return true
    }
    else {
      return false
    }
  };


  const submitBlog = async () => {

    if (!checkIsSignedIn()) {
      setState({ open: true, snackMessage: 'Failed, Not Signed In', vertical: 'bottom', horizontal: 'center', severity: 'error' });
    } else if (!validateBlogsFields()) {
      setState({ open: true, snackMessage: 'Failed, Missing Required Information', vertical: 'bottom', horizontal: 'center', severity: 'error' });
    } else {
      setLoading(true);

      let data = JSON.stringify(props.contents)
      //let newData = data.substring(1, data.length - 1);
      //console.log(originalDate)
      //console.log(bucketKey)
      /*
      var date = new Date("Wed, 04 May 2022");
   
      // Get year, month, and day part from the date
      var year = date.toLocaleString("default", { year: "numeric" });
      var month = date.toLocaleString("default", { month: "2-digit" });
      var day = date.toLocaleString("default", { day: "2-digit" });
      
      // Generate yyyy-mm-dd date string
      var formattedDate = month + "-" + day + "-" + year;
      console.log(formattedDate)
      */
      //convert to json
      const json =
      {
        "Title": title,
        "Author": author,
        "ArticleID": id,
        "Summary": summary,
        "DatePosted": datePosted,
        "Image": image,
        "Contents": contents
      }


      let bucketValues = bucketKey.split("/")
      let prefix = bucketValues[0]
      let key = bucketValues[1]

      let blogs = API + "/blogs/articles?action=UPDATE&prefix=" + prefix + "&key=" + key // this will call aws endpoint
      const customConfig = {
        headers: {
          "Content-Type": "application/json"
        }
      };

      await axios.post(blogs, JSON.stringify(json), customConfig)
        .then((response) => {
          setPosts(response.data.result)

        }).catch(error => {
          console.error(`Error: ${error}`);
        }).finally((x) => {
          setLoading(false);
          setState({ open: true, snackMessage: 'Submitted Blog Post', vertical: 'bottom', horizontal: 'center', severity: 'success' });

        });

      //post to lambda function

    }
  }


  //delete article

  const deleteArticle = async () => {
    console.log("deleting...")
    if (!checkIsSignedIn()) {
      setState({ open: true, snackMessage: 'Failed, Not Signed In', vertical: 'bottom', horizontal: 'center', severity: 'error' });
    
    } else {
      setLoading(true);

      let bucketValues = bucketKey.split("/")
      let prefix = bucketValues[0]
      let key = bucketValues[1]

      let blogs = API + "/blogs/articles?action=DELETE&prefix=" + prefix + "&key=" + key +"&articleId=" + id// this will call aws endpoint
      const customConfig = {
        headers: {
          "Content-Type": "application/json"
        }
      };

      await axios.post(blogs, customConfig)
        .then((response) => {
          setPosts(response.data.result)

        }).catch(error => {
          console.error(`Error: ${error}`);
        }).finally((x) => {
          setLoading(false);
          setState({ open: true, snackMessage: 'Submitted Blog Post', vertical: 'bottom', horizontal: 'center', severity: 'success' });

        });

      //post to lambda function

    }
  }

  return (
    <Container maxWidth="lg">
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={5000} onClose={handleClose} key={vertical + horizontal}>
        <Alert onClose={handleClose} severity={state.severity} sx={{ width: '100%' }}>
          {snackMessage}
        </Alert>
      </Snackbar>



      {!isEditModeOn && checkIsSignedIn() ?
        <Tooltip title="Edit" placement="left">
          <Fab
            color="primary"
            aria-label="edit"
            style={fabStyleEditSave}
            onClick={() => { setIsEditModeOn(true) }}
          >
            <EditIcon />
          </Fab>
        </Tooltip>
        :
        <div>
          {checkIsSignedIn() ?
            <div>
              <Tooltip title="Save" placement="left">

                <Fab
                  color="success"
                  aria-label="save"
                  style={fabStyleEditSave}
                  onClick={() => saveAllBlogChanges()}

                ><SaveIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Preview" placement="left">

                <Fab
                  color="secondary"
                  aria-label="preview"
                  style={fabStylePreview}
                  onClick={() => previewChanges()}

                ><VisibilityIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Add Paragraph" placement="left">

                <Fab
                  color="primary"
                  aria-label="preview"
                  style={fabStyleAddParagraph}
                  onClick={() => addParagraph()}

                ><PlaylistAddIcon />
                </Fab>
              </Tooltip>

              <Tooltip title="Delete Paragraph" placement="left">

                <Fab
                  color="warning"
                  aria-label="preview"
                  style={fabStyleDeleteParagraph}
                  onClick={() => removeParagraph()}

                ><PlaylistRemoveIcon />
                </Fab>
              </Tooltip>

              <Tooltip title="Delete Article" placement="left">

                <Fab
                  color="error"
                  aria-label="error"
                  style={fabStyleDeleteArticle}
                  onClick={() => deleteArticle()}

                ><DeleteIcon />
                </Fab>
              </Tooltip>
            </div> : null}
        </div>}


      {isEditModeOn ?
        <TextField
          required
          id="outlined-required"
          label="Title"
          defaultValue={title}
          onChange={handleTitleChange}
          fullWidth
        /> : null
      }
      <Typography component="div" variant="h3" align="left">
        <b>{title}</b>
      </Typography>

      {isEditModeOn ?
        <TextField
          required
          id="outlined-required"
          label="Summary"
          defaultValue={summary}
          onChange={handleSummaryChange}
          fullWidth
        /> : null
      }
      <Typography component="div" variant="h5" align="left" color="GrayText">
        {summary}
      </Typography>


      {isEditModeOn ?
        <TextField
          id="outlined-select-author"
          select
          required
          label="Author"
          defaultValue={author}
          value={author}
          onChange={handleAuthorChange}
          fullWidth
        >
          {authors.map((option) => (
            <MenuItem key={option.value} value={option.value}>{option.value}</MenuItem>
          ))}
        </TextField> : null
      }


      <Typography component="div" variant="h6" align="left">
        By <Typography component="span" variant="h6" align="left" sx={{ color: headerColor }}>
          <b>{author} </b>
        </Typography>
        {isEditModeOn ?
          <TextField
            required
            id="outlined-required"
            label="Date Posted (MM-DD-YYYY)"
            defaultValue={datePosted}
            onChange={handleDatePostedChange}
            fullWidth

          /> : null
        }
        <Typography component="span" variant="h6" align="left">
          on {new Date(datePosted).toDateString()}
        </Typography>
      </Typography>

      <br></br>
      {isEditModeOn ?
        <TextField
          required
          id="outlined-required"
          label="Cover Image (S3 URL)"
          defaultValue={coverImage}
          onChange={handleCoverImageChange}
          fullWidth


        /> : null
      }
      <Card>
        <CardMedia
          component="img"
          image={image}
        />
      </Card>
      <Typography component="div" variant="h6" align="right" color="GrayText">
        {caption}
      </Typography>
      <br></br>

      <Grid container spacing={1} sx={{ flexDirection: { xs: "column", md: "row", lg: "row" } }}>
        {contents.map((c) => (
          <div>
            {/*<Header text={c['Header']} color={headerColor} contents={c} />*/}
            {c['Body'].map((p, index) => (
              <div>
                <Paragraph
                  paragraphIndex={index}
                  image={p['Image']}
                  text={p['Paragraph']}
                  contents={c}
                  headerColor={headerColor}
                  isEditModeOn={isEditModeOn}
                ></Paragraph>
              </div>
            ))}
          </div>
        ))}
      </Grid>
    </Container>
  );
}



class BlogArticleTemplate extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return <div><Article
      id={this.props.id}
      title={this.props.title}
      image={this.props.image}
      date={this.props.date}
      author={this.props.author}
      summary={this.props.summary}
      contents={this.props.contents}
      caption={this.props.caption}
      bucketKey={this.props.bucketKey}
    />

    </div>;
  }
}

export default BlogArticleTemplate