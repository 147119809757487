import React, { useEffect, useState } from "react";
import '.././App.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from "axios";
import {useLocation, useParams} from 'react-router-dom';
import BlogArticleTemplate from "../components/BlogArticleTemplate";
import { API } from "../api"

function BlogArticle() {

    const location = useLocation()

    const [posts, setPosts] = useState('');
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
  
    
    useEffect(() => {
      handlePosts(location);
    }, []);
  
    
    const handlePosts = async (location) => {
      setLoading(true);
  
      //let blogs = API + "/blogs/articles?ArticleID=" + location.state.id // this will call aws endpoint
      let blogs = API + "/blogs/articles?key="+ location.state.bucketKey //this will call aws endpoint

   
      await axios.get(blogs)
        .then((response) => {
          console.log(response)
          setPosts(response.data.result)
  
        }).catch(error => {
          console.error(`Error: ${error}`);
        }).finally((x) => {
          setLoading(false);
  
        });
  
    };

  


    function displayDetailedBlogPost() {
        if (!loading && posts) {
          console.log(posts)
          return (
            <div>
              <Grid container direction="row" spacing={4}>
              <Grid item xs={12} sm={12} md={12} lg={12} alignContent="center">
                <BlogArticleTemplate 
                id={posts['ArticleID']} 
                title={posts['Title']} 
                image={posts['Image']}
                date={posts['DatePosted']} 
                author={posts['Author']}
                summary={posts['Summary']}
                contents={posts['Contents']}
                bucketKey={location.state.bucketKey}
                caption={posts['Caption']}
                 />
              </Grid>
            </Grid>
            </div>
    
          );
        }
      }

    return  (
    <Box>
      <div>
        {displayDetailedBlogPost()}
      </div>
  </Box>)
  
  }
  
  export default BlogArticle;