import React, { useEffect, useState } from "react";
import '.././App.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import { API } from "../api"
import axios from "axios";
import Avatar from '@mui/material/Avatar';


function AboutCard(props){
  const image = props.image
  const imageHeight = props.imageHeight
  const title = props.title
  const summary = props.summary
  const screenname = props.screenname
  const author = props.author
  const avatarImageUrl = props.avatarImageUrl
  const username = props.avatar
  const twitter_url = "https://twitter.com/" + username
  const avatarImageUrl_bigger = avatarImageUrl.replace("_normal", "_400x400")
  const twitter_photo ="https://twitter.com/jkfisher97/photo"
  const bigger ="https://pbs.twimg.com/profile_images/1576001896464404481/pSMpkOZI_400x400.jpg"


  return (
    <Card >
      <CardActionArea>

        <CardContent>
        <a href={twitter_url} title={"View @" + username} target="_blank" >
          <Avatar
            src={avatarImageUrl_bigger}
            align="center"

            sx={{ width: 400, height: 400, margin:"auto" }}>
            {author}

          </Avatar>
          </a>
          
        <Grid container spacing={2}>

           <Grid item xs={12}>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <Typography gutterBottom variant="body2" component="div">
              {screenname}
            </Typography>
            </Grid>
         </Grid>
         
        </CardContent>
      </CardActionArea>
    </Card>
  );
}


 
export default function () {


  const john = 'jkfisher'
  const robert = 'rmfisher'

  const [profileJohn, setProfileJohn] = useState({});
  const [profileRobert, setProfileRobert] = useState({});

  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState([])

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {

    setLoading(true)
    let user = API + "/twitter/users?screen_names=" + john + ',' + robert


    await axios.get(user)
      .then((response) => {
        let data = response.data.result
        setUsers(data)

        let j = getUser(data, john)
        let r = getUser(data, robert)
        setProfileJohn(j)
        setProfileRobert(r)


      }).catch(error => {
        console.error(`Error: ${error}`);
      }).finally((x) => {
        setLoading(false)

      });


  }

  function getUser(users, user){

    let found = {}
    if (users.length > 0 && user !== undefined){
      found = users.filter(function (x) {
        return x['username'] === user
      });

      if (found.length > 0){
        found = found[0]
      }

    }

    return found
  }


  return  (

  <Box>
    {!loading? 
      <div>

      <Grid container direction="row" spacing={4} justifyContent="center">
      <h1>About</h1>

        <Grid container direction="row" spacing={2} justifyContent="center">
          <Grid item xs={6} sm={6} md={6} lg={4} alignContent="center">
            <AboutCard 
              image="/static/images/greninja-incineroar/incineroar.jpg"
              imageHeight="300"
              title="John" 
              screenname="@doppelgamerjohn"
              avatarImageUrl={profileJohn['profile_image_url']}
              avatar={profileJohn['username']}>
            </AboutCard>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={4} alignContent="center">
            <AboutCard 
              image="/static/images/greninja-incineroar/greninja.jpg"
              imageHeight="300"
              title="Robert" 
              screenname="@doppelgamerrobert"
              avatarImageUrl={profileRobert['profile_image_url']}
              avatar={profileRobert['username']}
              >
            </AboutCard>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8} alignContent="center">
          <Typography variant="body1" component="div" align="left">
              So who are The Doppelgamers? First and foremost we are serious gamers, but we're also identical twin brothers - doppelgangers, you might say.  Or more commonly known as @doppelgamerjohn and @doppelgamerrobert.
              What kick-started our blog was discovering a new passion for virtual photography and sharing our screenshots with the world. We always enjoyed photo modes in our favorite games, but it came to the forefront while playing the legendary multiplayer Nintendo switch game, Super Smash Bros. Ultimate.
          </Typography>
          <Typography variant="body1" component="div" align="left">
              <br />
              We are enthusiastic/amateur Incineroar (@doppelgamerjohn) and Greninja (@doppelgamerrobert) Mains -  fated to duel for eternity, internet bandwidth permitting. So one day we thought it would be fun to capture screenshots of our epic battles using photo mode. And once we shared our favorite photos with the gaming community, we were excited to try photo modes in our favorite games.  We loved our new hobby, and we thought it might to fun to curate and share more gaming related content with the world - even if it's nothing fancy.
              So, thank you for stopping by and we hope you enjoy our blog about our random gaming adventures! 
            </Typography>
            <Typography variant="body1" component="div" align="center">
            <br />
            GG, The Doppelgamers
          </Typography>
          
        </Grid>
    </Grid>

  


    </div>: ""}
</Box>



)

}
