
export const API = "https://li90va3mzb.execute-api.us-west-2.amazonaws.com/doppelgamers_api_stage"

//export const TWITTER_CLIENT_ID = "ZmNad1ZVWS02NkxKcUZHRHJaUFI6MTpjaQ"
export const DISCORD_CLIENT_ID = "1068666466615959752"

//export const REDIRECT_URI = "http://localhost:3000/login"

//raw bucket will need to be actual domain  when completely working
//export const REDIRECT_URI = "http://ui.thedoppelgamers.com.s3-website-us-west-2.amazonaws.com/login"

export const REDIRECT_URI = "https://www.thedoppelgamers.com/login"
