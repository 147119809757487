import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
const CustomDatePicker = (props) => {


  const [value, setValue] = React.useState(dayjs(props.datePosted)) 
  
  const handleChangeStateClick = (newValue) => {
    console.log(newValue)
    setValue(newValue)
    //convert to proper date
    props.handleDatePostedChange(new Date(newValue));
  };


  

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker 
        label="Basic date picker"  
        defaultValue={dayjs(props.datePosted)}
        value={value}
        onChange={(newValue) => handleChangeStateClick(newValue)}/>
      </DemoContainer>
    </LocalizationProvider>
  );
}


class BasicDatePicker extends React.Component {

  constructor(props) {
    super(props)

   
  }

 
  render() {
    return (
     
       
        <CustomDatePicker
          datePosted={this.props.datePosted}
          handleDatePostedChange={this.props.handleDatePostedChange}
    />
   
    );
  }
}

export default BasicDatePicker

