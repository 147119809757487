import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import { API } from "../api"
import axios from "axios";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DOMPurify from 'dompurify'
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import BasicDatePicker from "./DatePicker";
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const buckets = [
    {
        value: 'both'
    },
    {
        value: 'robert'
    },
    {
        value: 'john'
    }
];

const authors = [
    {
        value: 'The Doppelgamers'
    },
    {
        value: 'Robert'
    },
    {
        value: 'John'
    }
];

const BlogForm = (props) => {

    /*
    const [profile, setProfile] = React.useState(() => {
       console.log("Blog form pro found")

        // getting stored value
        const saved = localStorage.getItem("profile");
        const initialValue = JSON.parse(saved);

        if (initialValue != null) {
            //console.log("username found")
            return initialValue["username"]
        } else {
            //console.log("blog not signed in")
            return null
        }

    });
*/

    //snackbar
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        snackMessage: '',
        severity: 'success'
    });
    const { vertical, horizontal, open, snackMessage } = state
    //

    const [prefix, setPrefix] = React.useState('both');//default value
    const [key, setKey] = React.useState(new Date().getTime());

    const [articleId, setArticleId] = React.useState(new Date().toISOString());
    const [author, setAuthor] = React.useState('');
    const [title, setTitle] = React.useState('TITLE');
    const [datePosted, setDatePosted] = React.useState('');
    const [summary, setSummary] = React.useState('SUMMARY');
    const [coverImage, setCoverImage] = React.useState("https://s3.us-west-2.amazonaws.com/images.thedoppelgamers.com/playstation/ps5-disc-and-digital.png");
    const [contents, setContents] = React.useState('');

    const [loading, setLoading] = React.useState(false);
    const [posts, setPosts] = React.useState('');
    const [error, setError] = React.useState('');

    const [inputFields, setInputFields] = React.useState([
        { Header: '', Body: [{ Paragraph: '', Image: '' }] },
    ])   

    const handlePrefixChange = (event) => {
        //setPrefix(event.target.value);
    };
    const handleKeyChange = (event) => {
        setKey(event.target.value);
    };
    const handleArticleIdChange = (event) => {
        setArticleId(event.target.value);

      
    };
    const handleAuthorChange = (event) => {
        setAuthor(event.target.value);

        switch (event.target.value){
            case "John":
                setPrefix('john');
                break;
            case "Robert":
                setPrefix('robert');
                break;
            default:
                setPrefix('both');
                break;
        }
        


    };
    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };
    const handleDatePostedChange = (event) => {
        //console.log(event)
        //console.log(event.target.value)
        let date =event
        // Get year, month, and day part from the date
        var year = date.toLocaleString("default", { year: "numeric" });
        var month = date.toLocaleString("default", { month: "2-digit" });
        var day = date.toLocaleString("default", { day: "2-digit" });
    
        // Generate yyyy-mm-dd date string
        var formattedDate = month + "-" + day + "-" + year;
        console.log("formatted: " + formattedDate)
        //props.handleDatePostedChange(new Date(newValue));

        setDatePosted(formattedDate);
    };
    const handleSummaryChange = (event) => {
        setSummary(event.target.value);
    };
    const handleCoverImageChange = (event) => {
        setCoverImage(event.target.value);
    };
    const handleContentsChange = (event) => {
        setContents(event.target.value);
    };

    const addFields = (event) => {
        let newfield = { Header: '', Body: [{ Paragraph: '', Image: '' }] };
        setInputFields([...inputFields, newfield])

    };

    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1)
        setInputFields(data)
    }

    const handleHeadersChange = (index, event) => {
        let data = [...inputFields];
        data[index]['Header'] = event.target.value
        setInputFields(data);
    }

    const handleParagraphsChange = (index, event) => {
        let data = [...inputFields];
        data[index]['Body'][0]['Paragraph'] = event.target.value
        setInputFields(data);
    }


    const validateHeadersParagraph = (index, event) => {
        let data = [inputFields];
        console.log(JSON.stringify(data))
        console.log(data)
    }

    const handleClose = () => {
        setState({ ...state, open: false });
    };


    const checkIsSignedIn = () => {
        /*//debugging
        return true;
        //end debugging
        */
        const saved = localStorage.getItem("profile");
        const profileData = JSON.parse(saved);

        if (profileData != null) {
            if (profileData["username"] == "rmfisher"
                || profileData["username"] == "jkfisher"
                || profileData["username"] == "thedoppelgamers") {
                return true
            }
        } else {
            //console.log("blog not signed in")
            return false
        }
    }

    const validateBlogsFields = () => {
        console.log(prefix)
        console.log(key)
        console.log(articleId)
        console.log(title)
        console.log(author)
        console.log(summary)
        console.log(datePosted)
        console.log(coverImage)


        if (prefix && key && articleId && title && author && summary && datePosted && coverImage) {
            return true
        }
        else {
            return false
        }
    };



    const submitBlog = async () => {
        setKey(new Date().getTime())

        if (!checkIsSignedIn()) {
            //console.log("Unable to write post; not signed in")
            setState({ open: true, snackMessage: 'Failed, Not Signed In', vertical: 'bottom', horizontal: 'center', severity: 'error' });
        } else if (!validateBlogsFields()) {
            setState({ open: true, snackMessage: 'Failed, Missing Required Information', vertical: 'bottom', horizontal: 'center', severity: 'error' });

        }

        else {
            //console.log("Submitting blog post")


            setLoading(true);
            let data = JSON.stringify([inputFields])
            let newData = data.substring(1, data.length - 1);

            //convert to json
            const json =
            {
                "Title": title,
                "Author": author,
                "ArticleID": articleId,
                "Summary": summary,
                "DatePosted": datePosted,
                "Image": coverImage,
                "Contents": JSON.parse(newData)
            }
           

            let blogs = API + "/blogs/articles?action=INSERT&prefix=" + prefix + "&key=" + key // this will call aws endpoint
            const customConfig = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            console.log(blogs)
            console.log(json)
            await axios.post(blogs, JSON.stringify(json), customConfig)
                .then((response) => {
                    console.log(response)
                    setPosts(response.data.result)

                }).catch(error => {
                    console.error(`Error: ${error}`);
                }).finally((x) => {
                    setLoading(false);
                    setState({ open: true, snackMessage: 'Submitted Blog Post', vertical: 'bottom', horizontal: 'center', severity: 'success' });

                });


        }
        //post to lambda function
    }


    const addNewBlog = async () => {
        setArticleId(new Date().toISOString())
        setKey(new Date().getTime())

        console.log("articleId:" + articleId)
        console.log("key:" + key)
        console.log("datePosted: " + datePosted)


        if (!checkIsSignedIn()) {
            //console.log("Unable to write post; not signed in")
            setState({ open: true, snackMessage: 'Failed, Not Signed In', vertical: 'bottom', horizontal: 'center', severity: 'error' });
        }else if (!key) {
            setState({ open: true, snackMessage: 'Failed, Missing Required Information: Key', vertical: 'bottom', horizontal: 'center', severity: 'error' });

        }

        else {
            //console.log("Submitting blog post")
            setLoading(true);
            //convert to json

            let data = JSON.stringify([inputFields])
            let newData = data.substring(1, data.length - 1);

            let date = new Date()
            // Get year, month, and day part from the date
            var year = date.toLocaleString("default", { year: "numeric" });
            var month = date.toLocaleString("default", { month: "2-digit" });
            var day = date.toLocaleString("default", { day: "2-digit" });

            // Generate yyyy-mm-dd date string
            var formattedDate = month + "-" + day + "-" + year;
            console.log("formatted: " + datePosted)
           
            
            //set article id to be unique timestamp string
            console.log("articleId:" + articleId)
            console.log("prefix:" + prefix)
            console.log("key:" + key)
            console.log("formatted: " + datePosted)

            if(datePosted){
                formattedDate = datePosted
            }

            const json =
            {
                "Title": title,
                "Author": author,
                "ArticleID": articleId,
                "Summary": summary,
                "DatePosted": formattedDate,
                "Image": "https://s3.us-west-2.amazonaws.com/images.thedoppelgamers.com/playstation/ps5-disc-and-digital.png",
                "Contents": JSON.parse(newData)
            }


            let blogs = API + "/blogs/articles?action=INSERT&prefix=" + prefix + "&key=" + key // this will call aws endpoint
            const customConfig = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            console.log(blogs)
            console.log(json)

            await axios.post(blogs, JSON.stringify(json), customConfig)
                .then((response) => {
                    console.log(response)
                    setPosts(response.data.result)

                }).catch(error => {
                    console.error(`Error: ${error}`);
                }).finally((x) => {
                    setLoading(false);
                    setState({ open: true, snackMessage: 'Submitted Blog Post', vertical: 'bottom', horizontal: 'center', severity: 'success' });

                });



        }
        //post to lambda function
    }



    return (
        <div>
            <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={5000} onClose={handleClose} key={vertical + horizontal}>
                <Alert onClose={handleClose} severity={state.severity} sx={{ width: '100%' }}>
                    {snackMessage}
                </Alert>
            </Snackbar>

            <Box
                component="form"
                noValidate
                autoComplete="off"
            >
                <div>
                    <Grid container spacing={1} sx={{ flexDirection: { xs: "column", md: "row", lg: "row" } }}>

{/*
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Prefix (bucketfolder/)"
                                defaultValue=""
                                onChange={handlePrefixChange}
                                fullWidth
                            />
                        </Grid>
    
                        <Grid item xs={12} sm={12} md={6} lg={6}>

                            <TextField
                                required
                                id="outlined-required"
                                label="Key (.json)"
                                defaultValue=""
                                onChange={handleKeyChange}
                                fullWidth
                            />
                        </Grid>
                        */}
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                                id="outlined-select-author"
                                select
                                required
                                label="Author"
                                value={author}
                                defaultValue={'The Doppelgamers'}
                                onChange={event => handleAuthorChange(event)}
                                fullWidth
                            >
                                {authors.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>{option.value}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            <BasicDatePicker
                               datePosted={datePosted}
                               handleDatePostedChange={handleDatePostedChange}
                               >
                               </BasicDatePicker>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>

                            <TextField
                                required
                                id="outlined-required"
                                label="Title"
                                defaultValue=""
                                onChange={handleTitleChange}
                                fullWidth
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>

                            <TextField
                                required
                                id="outlined-required"
                                label="Summary"
                                defaultValue=""
                                onChange={handleSummaryChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Cover Image (S3 URL)"
                                defaultValue="https://s3.us-west-2.amazonaws.com/images.thedoppelgamers.com/"
                                onChange={handleCoverImageChange}
                                fullWidth


                            />
                        </Grid>

                        {/*For each required paragraphs */}
                        {inputFields.map((input, index) => {
                            return (


                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        required
                                        label="Header"
                                        defaultValue=""
                                        fullWidth
                                        onChange={event => handleHeadersChange(index, event)}
                                    />
                                    {
                                        <TextField
                                            required
                                            label="Paragraph"
                                            defaultValue=""
                                            fullWidth
                                            onChange={event => handleParagraphsChange(index, event)}
                                            multiline
                                        />
                                    }
                                </Grid>

                            )
                        })
                        }


                    </Grid>

                </div>

                <Button
                    size="large"
                    color="primary"
                    startIcon={<AddBoxIcon />}
                    onClick={() => addFields()}>
                    Section
                </Button>
                <Button
                    size="large"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => removeFields()}>
                    Remove Section
                </Button>
                <Button
                    size="large"
                    color="success"
                    endIcon={<SendIcon />}
                    onClick={() => submitBlog()}>
                    Submit
                </Button>
                <Button
                    size="large"
                    color="warning"
                    endIcon={<SendIcon />}
                    onClick={() => addNewBlog()}>
                    Write Empty Blog (*Only Requires Key and Author")
                </Button>
            </Box>
        </div>
    );
}



class BlogArticleForm extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return <div><BlogForm />
        </div>;
    }
}

export default BlogArticleForm