import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import { CardActionArea } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';

const NewsCardSkeleton = (props) => {
  const { loading = false } = props;

  const category = props.category
  const id = props.id
  const title = props.title
  const image = props.image
  const date = new Date(props.date).toDateString()//format at Month Day, Year
  //remove the (@name)
  //const author = props.author.split('(')[0].trim()

  const summary = props.summary
  const bucketKey = props.bucketKey
  const avatar = props.avatar
  const link = props.link
  const featured = props.featured

  const navigate = useNavigate();

  const toBlogDetails = () => {
    if (!link) {

      navigate("/blog/".concat(title), {
        state: {
          id: id,
          title: title,
          bucketKey: bucketKey
        }
      });
    } else {
      //different link
      window.open(link);
    }
  }


  //margin alone
  //styling
  const rightAlignItem = {
    marginLeft: "auto"
  }
  //flex + margin
  //styling
  const leftAlignItem = {
    marginRight: "auto"
  }

  const parentFlexRight = {
    display: "flex",
    justifyContent: "flex-end"
  }

  return (
    <Card elevation={1} sx={{ display: 'flex', flexDirection: { xs: "row", md: "column" } }}>
      <Grid container spacing={1} sx={{ flexDirection: { xs: "row", md: "column" } }}
        justifyContent="center"
        alignItems="left">
        <Grid item xs={12} sm={12} md={12} >
          <CardHeader sx={{ padding: "8px" }}
            avatar={
              (<Skeleton animation="wave" variant="circular" width={40} height={40}></Skeleton>)

            }

            title={( <Skeleton animation="wave" />)}
            subheader={( <Skeleton animation="wave" />)}
          />

          <CardActionArea onClick={() => toBlogDetails()}>
            {featured ?
              <Box>

                
                  {/*}
                <CardMedia elevation={0}
                  component="img"
                  sx={{ maxHeight: 250, height: 250 }}
                  image={image}
                  alt=""
              />*/}
                    <Skeleton variant="rectangular" width={600} height={250} />

              </Box> :
              <Box
                >
                  {/*}
                <CardMedia elevation={0}
                  component="img"
                  sx={{ maxHeight: 200, height: 200 }}
                  image={image}
                  alt=""
              />*/}
                 <Skeleton variant="rectangular" width={600} height={200} />

              </Box>
            }
          </CardActionArea>

        </Grid>
        <Grid item xs={12} sm={12} md={12} >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}  >
            <CardContent sx={{ height: '100px', padding: '8px' }} >
              <Typography gutterBottom variant="body2" align='left' >
              <Skeleton variant="rectangular" width={675} height={100} />

              </Typography>

            </CardContent>

            {/*RIGHT*/}
            <CardActions
              style={{ display: "flex", justifyContent: "flex-end" }}>

              <Button
                size="small"
                onClick={() => toBlogDetails()}>
                Read more
              </Button>

              <IconButton style={{ display: "flex", justifyContent: "flex-end" }} aria-label="share">
                <ShareIcon />
              </IconButton>
            </CardActions>

          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}



class NewsMediaCardSkeleton extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return <div><NewsCardSkeleton
      id={this.props.id}
      title={this.props.title}
      image={this.props.image}
      date={this.props.date}
      author={this.props.author}
      summary={this.props.summary}
      bucketKey={this.props.bucketKey}
      avatar={this.props.avatar}
      link={this.props.link}
      featured={this.props.featured} />
    </div>;
  }
}

NewsMediaCardSkeleton.propTypes = {
  loading: PropTypes.bool,
};


export default NewsMediaCardSkeleton