import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';

function ActionAreaCard(props) {
    const image = props.image
    const title = props.title
    const alt = props.alt
    const height = props.height
    const summary = props.summary
    const prefix = props.prefix

    const navigate = useNavigate();

    const toPhotoGallery = () => {

      navigate("/vp/".concat(title), {
        state: {
          title: title,
          prefix: prefix
        }
      });
    }


  return (
    <Card sx={{ maxWidth: 400 }}>
      <CardActionArea onClick={() => toPhotoGallery()}>
        <CardMedia
          component="img"
          height={height}
          image={image}
          alt={alt}
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
           {summary}
          </Typography>
          <Button
                size="small"
                onClick={() => toPhotoGallery()}>
                Read more
              </Button>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

class PhotoActionAreaCard extends React.Component {
    constructor(props){
        super(props)
    }

    render() {
      return <div><ActionAreaCard 
      title={this.props.title} 
      image={this.props.image} 
      height={this.props.height} 
      alt={this.props.alt}
      summary={this.props.summary}
      prefix={this.props.prefix}/>
      </div>;
    }
  }

  export default PhotoActionAreaCard

  