import React, { useEffect, useState } from "react";
import '.././App.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from "axios";
import { useLocation, useParams } from 'react-router-dom';
import { API } from "../api"
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import GameLibraryTable from "../components/GameLibraryTable";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';

const itemData = [
  {
    img: '/static/images/games/zelda-tears-of-the-kingdom.jpg',
    title: 'The Legend of Zelda: Tears of the Kingdom',
    platform: 'Nintendo Switch',
    gamer: 'John',
    avatarImageUrl: '/static/images/greninja-incineroar/incineroar.jpg',
    status: 'Offline'

  },
  {
    img: '/static/images/games/zelda-tears-of-the-kingdom.jpg',
    title: 'The Legend of Zelda: Tears of the Kingdom',
    platform: 'Nintendo Switch',
    gamer: 'Robert',
    avatarImageUrl: '/static/images/greninja-incineroar/greninja.jpg',
    status: 'Online'

  }
];


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function GameLibrary() {

  const location = useLocation()

  const [gamesLibrary, setGamesLibrary] = useState([]);
  const [discordStatus, setDiscordStatus] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const [title, setTitle] = useState('');
  const [sortLetter, setSortLetter] = useState('');
  const [platform, setPlatform] = useState('');
  const [hasJohnPlayed, setHasJohnPlayed] = useState(false);
  const [hasRobertPlayed, setHasRobertPlayed] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);


  //snackbar
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    snackMessage: '',
    severity: 'success'
  });
  const { vertical, horizontal, open, snackMessage } = state

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    
    if (title) {
      setSortLetter(title.charAt(0).toUpperCase())

      if (title.toUpperCase().startsWith("THE")) {
        //console.log("Contains 'The'")
        setSortLetter(title.substring(4, title.length).toUpperCase().charAt(0))
      }
    }
  };

  const handlePlatformChange = (event) => {
    setPlatform(event.target.value);
  };

  const handleHasJohnPlayedChange = (event) => {
    setHasJohnPlayed(event.target.value);
  };

  const handleHasRobertPlayedChange = (event) => {
    setHasRobertPlayed(event.target.value);
  };

  useEffect(() => {
    handlePosts(location);
    handleGetDiscordStatus();
    setIsSignedIn(checkIsSignedIn())
  }, []);

  const handlePosts = async (location) => {
    setLoading(true);

    let games = API + "/games" //this will call aws endpoint
    //console.log(games)
    await axios.get(games)
      .then((response) => {
        //console.log(response)
        setGamesLibrary(response.data.result)

      }).catch(error => {
        console.error(`Error: ${error}`);
      }).finally((x) => {
        setLoading(false);

      });

  };

  const handleGetDiscordStatus = async (location) => {
    setLoadingStatus(true);

    let url = API + "/discord/status" //this will call aws endpoint
    //console.log(games)
    await axios.get(url)
      .then((response) => {
        console.log(response)
        setDiscordStatus(response.data.result)

        console.log(discordStatus[0])
      }).catch(error => {
        console.error(`Error: ${error}`);
      }).finally((x) => {
        setLoadingStatus(false);

      });

  };


  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const checkIsSignedIn = () => {
    //TODO
    const saved = localStorage.getItem("profile");
    const profileData = JSON.parse(saved);

    if (profileData != null) {
      if (profileData["username"] == "rmfisher"
        || profileData["username"] == "jkfisher"
        || profileData["username"] == "thedoppelgamers") {
        console.log("signed in as: " + profileData["username"])
        return true
      }
    } else {
      //console.log("blog not signed in")
      return false
    }
  }

  const validateGameFields = () => {
    if (title && platform && (hasJohnPlayed || hasRobertPlayed)) {
      if (title) {
        console.log("setting sort Letter")
        setSortLetter(title.charAt(0).toUpperCase())
  
        if (title.toUpperCase().startsWith("THE")) {
          //console.log("Contains 'The'")
          setSortLetter(title.substring(4, title.length).toUpperCase().charAt(0))
        }
        console.log(sortLetter)
      }
      return true
    }
    else {
      return false
    }
  };


  const addGame = async () => {
    //console.log("Adding game...")
    if (!isSignedIn) {
      //console.log("Unable to add game; not signed in")
      setState({ open: true, snackMessage: 'Failed, Not Signed In', vertical: 'bottom', horizontal: 'center', severity: 'error' });
    } else if (!validateGameFields()) {
      setState({ open: true, snackMessage: 'Failed, Missing Required Information', vertical: 'bottom', horizontal: 'center', severity: 'error' });

    }

    else {
      //console.log("Submitting Game")
      setLoading(true);
      //convert to json
      const json =
      {
        "Title": title,
        "SortLetter": sortLetter,
        "Platform": platform,
        "HasJohnPlayed": hasJohnPlayed,
        "HasRobertPlayed": hasRobertPlayed,
      }

      //console.log(json)

      let gamesURL = API + "/games" // this will call aws endpoint
      const customConfig = {
        headers: {
          "Content-Type": "application/json"
        }
      };
      await axios.post(gamesURL, JSON.stringify(json), customConfig)
        .then((response) => {
          //console.log(response)
          //setPosts(response.data.result)

        }).catch(error => {
          console.error(`Error: ${error}`);
        }).finally((x) => {
          setLoading(false);
          setState({ open: true, snackMessage: 'Added Game', vertical: 'bottom', horizontal: 'center', severity: 'success' });
          //set inputs to empty
          setTitle('')
          setPlatform('')
          setSortLetter('')
          setHasJohnPlayed(false)
          setHasRobertPlayed(false)
        });


    }
    //post to lambda function
  }

  function displayGameLibraryForm() {


    return (
      <Box
        component="form"
        noValidate
        autoComplete="off"
      >
        <Grid container spacing={1} sx={{ flexDirection: { xs: "column", md: "row", lg: "row" } }}>

          <Grid item xs={12} sm={12} md={6} lg={3}>
            <InputLabel id="title-label">Title</InputLabel>

            <TextField
              required
              id="title-label"
              label="Title"
              defaultValue=""
              value={title}
              onChange={handleTitleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <InputLabel id="platform-select-label">Platform</InputLabel>
            <Select
              labelId="platform-select-label"
              id="platform-select"
              value={platform}
              label="Platform"
              onChange={handlePlatformChange}
              fullWidth
            > <MenuItem value={"Game Boy"}>Game Boy</MenuItem>
              <MenuItem value={"Game Boy Advance"}>Game Boy Advance</MenuItem>
              <MenuItem value={"GameCube"}>GameCube</MenuItem>
              <MenuItem value={"DS"}>DS</MenuItem>
              <MenuItem value={"3DS"}>3DS</MenuItem>
              <MenuItem value={"Wii"}>Wii</MenuItem>
              <MenuItem value={"Switch"}>Switch</MenuItem>
              <MenuItem value={"PS1"}>PS1</MenuItem>
              <MenuItem value={"PS2"}>PS2</MenuItem>
              <MenuItem value={"PS3"}>PS3</MenuItem>
              <MenuItem value={"PS4"}>PS4</MenuItem>
              <MenuItem value={"PS5"}>PS5</MenuItem>
              <MenuItem value={"PSP"}>PSP</MenuItem>
              <MenuItem value={"PS Vita"}>PS Vita</MenuItem>
              <MenuItem value={"Xbox"}>Xbox</MenuItem>
              <MenuItem value={"Xbox 360"}>Xbox 360</MenuItem>
              <MenuItem value={"PC"}>PC</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <InputLabel id="john-played-select-label">John Played</InputLabel>
            <Select
              labelId="john-played-select-label"
              id="john-played-select"
              value={hasJohnPlayed}
              label="John Played"
              onChange={handleHasJohnPlayedChange}
              fullWidth
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={3}>
            <InputLabel id="robert-played-select-label">Robert Played</InputLabel>
            <Select
              labelId="robert-played-select-label"
              id="robert-played-select"
              value={hasRobertPlayed}
              label="Robert Played"
              onChange={handleHasRobertPlayedChange}
              fullWidth
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </Grid>
          <Button
            size="large"
            color="success"
            endIcon={<div></div>}
            onClick={() => addGame()}>
            Add Game
          </Button>

        </Grid>
      </Box>
    )
  }

  function displayGameLibrary() {
    if (!loading && gamesLibrary) {
      //console.log(gamesLibrary)

      let letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

      return (
        <div>


          <Grid container direction="row" spacing={2} justifyContent="center">
            {letters.map((letter) => (
              <Grid item xs={12} sm={12} md={6} lg={6} alignContent="center">
                <Typography variant="h4" component="div" align="left">
                  {letter}
                </Typography>
                <GameLibraryTable
                  games={gamesLibrary.filter(game => game.SortLetter == letter)}
                  isSignedIn={isSignedIn}>
                </GameLibraryTable>
              </Grid>
            ))}
          </Grid>
        </div>

      );
    }
  }

  function displayDiscordGameStatus() {
    if (!loadingStatus && discordStatus) {
      return (
        <div>


          <Grid container direction="row" spacing={2} justifyContent="center">

            {discordStatus.map((item) => (
              <Grid item xs={6} sm={6} md={4} lg={4} alignContent="center">
                <Card>
                  <CardHeader sx={{ padding: "8px" }}
                    avatar={
                      <Avatar
                        src={item.Avatar}
                        align="left"
                        style={{ marginRight: "10px" }}>
                        {item.Name}

                      </Avatar>

                    }
                    title={item.Name}
                    subheader={item.Status}
                  />
                  <CardMedia
                    component="img"
                    width="480"
                    image={item.Image}
                  />
                  <CardContent >
                    <Typography variant="h6" component="div" align="left">
                      {item.Game ? item.Game : "Nothing"}
                    </Typography>
                    <Typography variant="subtitle1" component="div" align="left">
                      {item.Platform ? item.Platform : "Nothing"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>

      );
    }
  }



  return (
    <Box>
      <div>
        <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={5000} onClose={handleClose} key={vertical + horizontal}>
          <Alert onClose={handleClose} severity={state.severity} sx={{ width: '100%' }}>
            {snackMessage}
          </Alert>
        </Snackbar>
        <Typography variant="h4" component="div" align="center">
          Game Library
        </Typography>
        <Typography variant="h5" component="div" align="left">
          Now Playing
        </Typography>
        {displayDiscordGameStatus()}

        {isSignedIn ? displayGameLibraryForm() : null}
        {displayGameLibrary()}
      </div>
    </Box>)

}

export default GameLibrary;