import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import { CardActionArea } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NewsMediaCardSkeleton from './NewsMediaCardSkeleton';
import Skeleton from '@mui/material/Skeleton';

const NewsCard = (props) => {

  //show a skeleton
  const isLoading = props.isLoading

  const category = props.category
  const id = props.id
  const title = props.title
  const image = props.image
  const date = new Date(props.date).toDateString()//format at Month Day, Year
  //remove the (@name)
  const author = props.author

  const summary = props.summary
  const bucketKey = props.bucketKey
  const avatar = props.avatar
  const link = props.link
  const featured = props.featured

  const navigate = useNavigate();

  const toBlogDetails = () => {
    if (!link) {

      navigate("/blog/".concat(title), {
        state: {
          id: id,
          title: title,
          bucketKey: bucketKey
        }
      });
    } else {
      //different link
      window.open(link);
    }
  }

  if (!isLoading) {

    let formatAuthor = author.split('(')[0].trim()

    let avatarImageUrl = "/static/images/logo2.png"
    if (author == "John") {
      avatarImageUrl = "/static/images/greninja-incineroar/incineroar.jpg"
    } else if (author == "Robert") {
      avatarImageUrl = "/static/images/greninja-incineroar/greninja.jpg"
    } else if (author == "The Doppelgamers") {
      avatarImageUrl = "/static/images/logo2.png"
    } else {
      avatarImageUrl = avatar

    }

    var cardFont = 'large'

    if (summary.length < 100) {
      cardFont = 'large'
    } else if (summary.length > 100 && summary.length < 180) {
      cardFont = 'medium'
    } else if (summary.length > 180) {
      cardFont = 'small'
    }


    return (
      <Card elevation={1} sx={{ display: 'flex', flexDirection: { xs: "row", md: "column" } }}>
        <Grid container spacing={1} sx={{ flexDirection: { xs: "row", md: "column" } }}
          justifyContent="center"
          alignItems="left">
          <Grid item xs={12} sm={12} md={12} >
            <CardHeader sx={{ padding: "8px" }}
              avatar={
                isLoading ?
                  (<Skeleton animation="wave" variant="circular" width={40} height={40}></Skeleton>)
                  :
                  <Avatar
                    src={avatarImageUrl}
                    align="left"
                    style={{ marginRight: "10px" }}>
                    {formatAuthor}
                  </Avatar>
              }

              title={(isLoading ? <Skeleton animation="wave" /> : formatAuthor
              )}
              subheader={(isLoading ? <Skeleton animation="wave" /> : date)}
            />

            {isLoading ?

              <CardActionArea onClick={() => toBlogDetails()}>
                {featured ?
                  <Box>
                    <Skeleton variant="rectangular" width={600} height={250} />
                  </Box> :
                  <Box
                  >
                    <Skeleton variant="rectangular" width={600} height={200} />
                  </Box>
                }
              </CardActionArea>
              :
              <CardActionArea onClick={() => toBlogDetails()}>
                {featured ?
                  <Box
                    sx={{
                      maxHeight: 250,
                      height: 250,
                      bgcolor: 'primary.main',
                      '&:hover': {
                        backgroundColor: 'primary.dark',
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}>
                    <CardMedia elevation={0}
                      component="img"
                      sx={{ maxHeight: 250, height: 250 }}
                      image={image}
                      alt=""
                    />
                  </Box> :
                  <Box
                    sx={{
                      maxHeight: 200,
                      height: 200,
                      bgcolor: 'primary.main',
                      '&:hover': {
                        backgroundColor: 'primary.dark',
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}>
                    <CardMedia elevation={0}
                      component="img"
                      sx={{ maxHeight: 200, height: 200 }}
                      image={image}
                      alt=""
                    />
                  </Box>
                }
              </CardActionArea>


            }
          </Grid>
          <Grid item xs={12} sm={12} md={12} >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}  >
              <CardContent sx={{ height: '100px', padding: '8px' }} >
                <Typography gutterBottom variant="body2" align='left' >
                  {isLoading ? 
                  <Skeleton variant="rectangular" width={6750} height={100} />
                    : summary}
                </Typography>

              </CardContent>

              {/*RIGHT*/}
              <CardActions
                style={{ display: "flex", justifyContent: "flex-end" }}>

                <Button
                  size="small"
                  onClick={() => toBlogDetails()}>
                  Read more
                </Button>

                <IconButton style={{ display: "flex", justifyContent: "flex-end" }} aria-label="share">
                  <ShareIcon />
                </IconButton>
              </CardActions>

            </Box>
          </Grid>
        </Grid>
      </Card>
    );
  } else {
    return (<NewsMediaCardSkeleton />)
  }
}



class NewsMediaCard extends React.Component {

  constructor(props) {
    super(props)
  }

  render() {
    return <div><NewsCard
      id={this.props.id}
      title={this.props.title}
      image={this.props.image}
      date={this.props.date}
      author={this.props.author}
      summary={this.props.summary}
      bucketKey={this.props.bucketKey}
      avatar={this.props.avatar}
      link={this.props.link}
      featured={this.props.featured}
      isLoading={this.props.isLoading} />
    </div>;
  }
}

export default NewsMediaCard