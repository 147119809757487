import React, { useEffect, useState } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import Grid from '@mui/material/Grid';
import {
  Link, useSearchParams
} from 'react-router-dom';
import axios from "axios";
import { API, REDIRECT_URI, DISCORD_CLIENT_ID } from "../api"
import TwitterIcon from '@mui/icons-material/Twitter';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Redirect } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

const pagesRouting = [
  /*{ 'name': 'News', 'to': '/', 'value': '/' },*/
  { 'name': 'Blog', 'to': '/blog', 'value': '/blog' },
  { 'name': 'Photography', 'to': '/vp', 'value': '/vp' },
  { 'name': 'Game Library', 'to': '/library', 'value': '/library' }
  /*{ 'name': 'Events', 'to': '/events', 'value': '/events' },
  { 'name': 'Recognitions', 'to': '/recognitions', 'value': '/recognitions' }*/

];

const settings = ['Profile', 'Logout'];
function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      content: '""',
    },
  },
  
}));


const ResponsiveAppBar = (props) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    snackMessage: ''
  });
  const { vertical, horizontal, open, snackMessage } = state
  const [profile, setProfile] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("profile");
    const initialValue = JSON.parse(saved);
    return initialValue || null;
  });
  const [accessToken, setAccessToken] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("accessToken");
    const initialValue = JSON.parse(saved);
    return initialValue || null;
  });
  const [refreshToken, setRefreshToken] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("refreshToken");
    const initialValue = JSON.parse(saved);
    return initialValue || null;
  });

  const navigate = useNavigate();

  const getAvatar = () =>{
    let url = "https://cdn.discordapp.com/avatars/" + profile['id'] + "/" + profile['avatar'] 
    return url
  }

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  useEffect(() => {
    const get_credentials = async () => {

      if (accessToken == null) {
  
        const code = getParam('code')
  
        if (code !== null) {
  
          let content = {
            headers: {
              'Authorization': code
            }
          }
  
          let authorize_url = API + "/discord/login"

          console.log(authorize_url)
          console.log(content)
  
          await axios.get(authorize_url, content)
            .then((response) => {

              console.log(response)

              setProfile(response.data['profile'])
              setAccessToken(response.data['access_token'])
              setRefreshToken(response.data['refresh_token'])
              
              console.log(response.data['profile']['avatar'])
  
              // storing input name
              localStorage.setItem("profile", JSON.stringify(response.data['profile']));
              localStorage.setItem("accessToken", JSON.stringify(response.data['access_token']));
              localStorage.setItem("refreshToken", JSON.stringify(response.data['refresh_token']));
  
  
            }).catch(error => {
              console.log(error)
            }).finally((x) => {
              deleteParam('code')
              deleteParam('state')
              setState({ open: true, snackMessage: 'Signed in with Discord', vertical: 'bottom', horizontal: 'center' });
            });
        }
      }
    }

      get_credentials()
      // make sure to catch any error
      .catch(console.error);

  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };


  const getParam = (key) => {
    if (searchParams.has(key)) {
      const token = searchParams.get(key);
      if (token) {
        return token
      }
    }
    return null
    
  }

  const deleteParam = (key) => {
    if (searchParams.has(key)) {
      const token = searchParams.get(key);
      if (token) {
        searchParams.delete(key);
        setSearchParams(searchParams);
      }
    }
  }

  

  const auth_url = () => {
    let token_url = "https://discord.com/oauth2/authorize"
    let params = {
      'response_type': 'code',
      'client_id': DISCORD_CLIENT_ID,
      'redirect_uri': REDIRECT_URI,
      'scope': 'identify',
      'state': 'state',
     
    }
    token_url += "?"
    let index = 0
    for (const [key, value] of Object.entries(params)) {
      if (index > 0) {
        token_url += "&"
      }

      token_url += (key + "=" + value)
      index++
    }
    const encoded = encodeURI(token_url);
    return encoded

  }


  const logout = async () => {
      if (profile != null && !isEmpty(profile)){
      let content = {
        headers: {
          'Authorization': accessToken
        }
      }

      let authorize_url = API + "/discord/logout"

      await axios.get(authorize_url, content)
        .then((response) => {
          console.log(response)
          setProfile(null)


        }).catch(error => {
          console.log(error)
        }).finally((x) => {
          handleCloseUserMenu()

          //remove the profile and tokens
          localStorage.clear()
          setAccessToken(null)
          setRefreshToken(null)
          setProfile(null)
          setState({ open: true, snackMessage: 'Signed out of Discord', vertical: 'bottom', horizontal: 'center' });
          navigate("/");
        });
      }
    
  }


  return (
    <AppBar sx={{ backgroundColor: 'white' }} elevation={1}>
      <Container maxWidth="xl">
       <Snackbar  anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={5000} onClose={handleClose}  key={vertical + horizontal}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {snackMessage} 
        </Alert>
      </Snackbar>




        <Toolbar disableGutters >
          <Box sx={{ flexShrink: 0 }}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'black',
                textDecoration: 'none',
              }}
            >

              {<img src="/static/images/navbar.png" class="banner" ></img>}
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{color:"black"}}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'flex' },
              }}
            >
              {pagesRouting.map((page) => (
                <MenuItem key={page['name']} to={page['to']}
                  value={page['value']}
                  component={Link}
                  handleChange={handleCloseNavMenu}
                >
                  <Typography textAlign="center">{page['name']}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'black',
              textDecoration: 'none',
            }}
          >
            <img src="/static/images/logo_transparent.png" style={{ minHeight: '50px', maxHeight: '50px' }}></img>

          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pagesRouting.map((page) => (
              <Button
                key={page['name']}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'black', display: 'block' }}
                to={page['to']}
                value={page['value']}
                component={Link}
              >
                {page['name']}
              </Button>
            ))}
          </Box>
 
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={profile != null  && !isEmpty(profile)? "Open settings": "Sign in"}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {profile != null  && !isEmpty(profile)? 
                <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                  >
                <Avatar alt="online" src={getAvatar()} />

                </StyledBadge>:  <Avatar alt={""} src={""} />}
                {profile != null && !isEmpty(profile)? 
                <Typography textAlign="center" variant="button" >Hello, {profile['username']}</Typography>: <Typography textAlign="center" variant="button"></Typography>}
              </IconButton>
       
            </Tooltip>

           

            
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {profile == null || isEmpty(profile)?
               <MenuItem component="a" href={auth_url()} title="Login with Discord" sx={{cursor:"pointer"}}>
                <Grid container direction="row" alignItems="center"  spacing={1}>
                  <Grid item>
                    <Typography textAlign="center">Login <span><img src='static/images/discord-mark-blue.svg' width="25px" /></span></Typography>
                    </Grid>
                </Grid>
                </MenuItem> : 
                
                <MenuItem>
                <Grid container direction="row" alignItems="center"  spacing={1}>
                  <Grid item>
                    <Typography textAlign="center">Signed in as @{profile['username']+"#"+profile['discriminator']}</Typography>
                    </Grid>
                </Grid>
                </MenuItem>}
              {profile != null && !isEmpty(profile)?
               

                <MenuItem onClick={logout} title="Logout from Discord" sx={{cursor:"pointer"}}>
                <Grid container direction="row" alignItems="center" spacing={1}>
                  <Grid item >
                    <Typography textAlign="center">Logout <span><img src='static/images/discord-mark-blue.svg' width="25px" /></span></Typography>
                    </Grid>
                </Grid>
              </MenuItem>: ""}
                {/*
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}*/}
            </Menu>
          </Box>
            

        </Toolbar>

      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
