import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tabbar from './components/Tabbar';
import { Route, BrowserRouter, Routes, Link, Navigate  } from "react-router-dom";
import About from "./pages/About";
import Blog from "./pages/Blog";
import News from './pages/News';
import Events from './pages/Events';
import VP from './pages/VP';
import Recognitions from './pages/Recognitions';
import Archive from './pages/Archive';
import BlogArticle from './pages/BlogArticle';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import Footer from './components/Footer';
import VPGallery from './pages/VPGallery';
import GameLibrary from './pages/GameLibrary';

import axios from "axios";
import { API } from "./api"
import { ThemeProvider, createTheme } from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';



function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );


  return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <div className="App">
      <BrowserRouter>
        <Box sx={{ paddingLeft: '2%', paddingRight: '2%'}}>
          <Grid container direction="row" spacing={10} alignItems="flex-start" justifyContent="flex-start">
            <Grid item xs="12" sm="12" md="12" lg="12" >
              <ResponsiveAppBar />
            </Grid>
            <Grid item xs="12" sm="12" md="12" lg="12" >
            <Routes>
              <Route path="/" element={<Blog />} />
              <Route path="/login" element={<Blog />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:title" element={<BlogArticle />} />
              <Route path="/vp" element={<VP />} />
              <Route path="/vp/:gallery" element={<VPGallery />} />
              <Route path="/about" element={<About />} />
              <Route path="/library" element={<GameLibrary />} />
              {/*<Route path="/events" element={<Events />} />
              <Route path="/recognitions" element={<Recognitions />} />*/}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            </Grid>
          </Grid>
         
        </Box>

        <Footer />
      </BrowserRouter>
    </div>
    </ThemeProvider>

  );
}

export default App;
